import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appSelectText]'
})
export class SelectTextDirective {
  constructor(private el: ElementRef) {}

  @HostListener('focus', ['$event']) onFocus(event: FocusEvent) {
    setTimeout(() => {
      this.el.nativeElement.select();
    }, 0);
  }
}
