import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import jspdf from 'jspdf';
import autoTable from 'jspdf-autotable';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/shared-module/constants';
import { GlobalResponse } from 'src/app/shared-module/models';
import { NonSecureService } from '../non-secure.service';

@Component({
  selector: 'app-prescription-template',
  templateUrl: 'prescription-template.component.html',
  styleUrls: ['prescription-template.component.scss'],
  providers: [DatePipe]
})
export class PrescriptionTemplateComponent implements OnInit {
  mainData: any = {};
  prescription_id: string;
  base64ImageString: any;
  rowData: any[] = [];
  lineOfTreatments: any;
  medicalHistory: any;
  @BlockUI() blockUI: NgBlockUI;
  subTotal = 0;

  constructor(
    private titleService: Title,
    private toastr: ToastrService,
    private service: NonSecureService,
    private datepipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.prescription_id = this.route.snapshot.paramMap.get('prescription_id');
    if (this.prescription_id.length > 10) {
      this.fetchPrescriptionDetails();
    } else {
      this.router.navigate([Constants.PET_DASHBOARD_URL]);
    }
  }

  fetchPrescriptionDetails() {
    this.blockUI.start();
    this.service.fetchPrescriptionDetails(this.prescription_id).subscribe(
      (res: GlobalResponse) => {
        if (res && res.message && res.message === Constants.STATUS_SUCCESS && res.result) {
          this.mainData = res.result;
          if (
            this.mainData.lineOfTreatments &&
            this.mainData.lineOfTreatments[0] &&
            this.mainData.lineOfTreatments[0].pet_findings_status
          ) {
            this.lineOfTreatments = this.mainData.lineOfTreatments[0];
          }
          if (
            this.mainData.purpose_of_visit &&
            this.mainData.purpose_of_visit[0] &&
            this.mainData.purpose_of_visit[0].medical_history
          ) {
            this.medicalHistory = this.mainData.purpose_of_visit[0].medical_history;
          }
          this.subTotal = Math.floor(this.mainData.discount + this.mainData.amount);
          this.titleService.setTitle('Prescription ' + this.mainData.prescription_no);
          this.initData();
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.toastr.error(error);
      }
    );
  }

  initData() {
    this.createMainData(this.mainData.medicines, 'medicine');
    // this.createMainData(this.mainData.disposables, 'disposable', Constants.DISPOSABLE_VALUE);
    this.createMainData(this.mainData.injectables, 'injectable', Constants.INJECTABLE_VALUE);
    // this.createMainData(this.mainData.vaccinations, 'vaccination', Constants.VACCINATION_VALUE);
    if (this.mainData.clinic.logo_url && this.mainData.clinic.logo_url.length > 10) {
      this.getBase64ImageFromUrl(this.mainData.clinic.logo_url).then(base64 => {
        this.base64ImageString = base64;
      });
    }
    if (window.innerWidth <= 700) {
      setTimeout(() => {
        this.actionButton('print');
      }, 700);
    }
  }

  createMainData(listData: any, valueType: string, type: string = '') {
    for (const item of listData) {
      const tempItem = {} as any;
      if (item[valueType]) {
        if (type) {
          tempItem.medicine = item[valueType].name + ' - ';
          tempItem.medicine += type;
        } else {
          tempItem.medicine = item[valueType].name + ' - ';
          tempItem.medicine += item[valueType].category;
        }
        if (valueType === 'medicine') {
          tempItem.dose = item[valueType].timings
            ? item[valueType].timings.morning +
              ' - ' +
              item[valueType].timings.afternoon +
              ' - ' +
              item[valueType].timings.night
            : '';
        } else {
          tempItem.dose = '';
        }
        if (item[valueType].consume_days > 1) {
          tempItem.durationIns = item[valueType].consume_days + ' days';
        } else if (item[valueType].consume_days >= 0 && item[valueType].consume_days === 1) {
          tempItem.durationIns = item[valueType].consume_days + ' day';
        } else {
          tempItem.durationIns = '';
        }

        if (item[valueType].remarks) {
          tempItem.durationIns += item[valueType].remarks ? ' - ' + item[valueType].remarks : '';
        } else if (item[valueType].instructions) {
          tempItem.durationIns += item[valueType].instructions ? ' - ' + item[valueType].instructions : '';
        }

        let counter = 0;
        for (const tItem of this.rowData) {
          if (
            tItem.dose === tempItem.dose &&
            tItem.durationIns === tempItem.durationIns &&
            tItem.medicine === tempItem.medicine
          ) {
            counter++;
            break;
          }
        }

        if (counter === 0) {
          this.rowData.push(tempItem);
        }
      }
    }
    this.blockUI.stop();
  }

  async getBase64ImageFromUrl(imageUrl) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result);
        },
        false
      );
      reader.onerror = () => reject(this);

      reader.readAsDataURL(blob);
    });
  }

  actionButton(type: string) {
    const doc = new jspdf('p', 'mm', 'a4');

    // Set font and text color
    doc.setFontSize(18);
    doc.setTextColor(40);

    // Add clinic logo if available
    if (this.mainData.clinic.logo_url.length > 10 && this.base64ImageString) {
      try {
        doc.addImage(this.base64ImageString, 167, 15, 30, 30);
      } catch (error) {
        console.error('Error adding clinic logo:', error);
      }
    }

    // Draw separating lines
    if (this.mainData.clinic.clinic_gst || this.mainData.clinic.license_number) {
      doc.line(11, 48, 200, 48);
    } else {
      doc.line(11, 45, 200, 45);
    }

    const lineYPosition = this.mainData.checkup.department === 'IPD' ? 82 : 75;
    doc.line(10, lineYPosition, 200, lineYPosition);

    // Prepare table data for medicines (sanitize rowData)
    const tempArray = Array.isArray(this.rowData)
      ? this.rowData.map((item, index) => ({
          index: index + 1,
          medicine: item.medicine || '',
          category: item.category || '',
          dose: item.dose || '',
          durationIns: item.durationIns || ''
        }))
      : [];

    // Clinic details (sanitize mainData values)
    autoTable(doc, {
      body: [
        [{ content: this.mainData.clinic.name, styles: { halign: 'left', fontSize: 20 } }],
        [
          {
            content: this.mainData.clinic.address,
            styles: { halign: 'left', fontSize: 7, textColor: 'gray' }
          }
        ],
        [
          {
            content:
              (this.mainData.clinic.phone_no ? 'Ph: ' + this.mainData.clinic.phone_no : '') +
              (this.mainData.clinic.phone_no2 ? ' / ' + this.mainData.clinic.phone_no2 : '') +
              (this.mainData.clinic.website
                ? (this.mainData.clinic.phone_no ? '   ||   W: ' : 'W: ') + this.mainData.clinic.website
                : '') +
              (this.mainData.clinic.email_id
                ? (this.mainData.clinic.website || this.mainData.clinic.phone_no ? '   ||   E: ' : 'E: ') +
                  this.mainData.clinic.email_id
                : ''),
            styles: { halign: 'left', fontSize: 8 }
          }
        ],
        [
          {
            content:
              (this.mainData.clinic.clinic_gst ? 'GST: ' + this.mainData.clinic.clinic_gst : '') +
              (this.mainData.clinic.license_number
                ? (this.mainData.clinic.clinic_gst ? '   ||   License: ' : 'License: ') +
                  this.mainData.clinic.license_number
                : ''),
            styles: { halign: 'left', fontSize: 8 }
          }
        ]
      ],
      theme: 'plain',
      columnStyles: { 0: { cellWidth: 160 } }
    });

    // Header details (sanitize values)
    const headerArray = [];

    const headerDetailsArrayRow1 = [
      { content: 'Pet Name: ' + this.mainData.pet.name, styles: { halign: 'left', fontSize: 8 } },
      {
        content: 'Owner Name: ' + this.mainData.customer.first_name + ' ' + this.mainData.customer.last_name,
        styles: { halign: 'left', fontSize: 8 }
      },
      {
        content: 'Date: ' + this.datepipe.transform(this.mainData.prescription_date, Constants.DATE_FORMAT) || '',
        styles: { halign: 'left', fontSize: 8 }
      }
    ];
    headerArray.push(headerDetailsArrayRow1);

    const headerDetailsArrayRow2 = [
      { content: 'Pet ID: ' + this.mainData.pet.pet_id, styles: { halign: 'left', fontSize: 8 } },
      {
        content: 'Owner Phone No: ' + this.mainData.customer.phone_no,
        styles: { halign: 'left', fontSize: 8 }
      },
      {
        content: 'Prescription No: ' + this.mainData.prescription_no,
        styles: { halign: 'left', fontSize: 8 }
      }
    ];
    headerArray.push(headerDetailsArrayRow2);

    const headerDetailsArrayRow3 = [
      {
        content: 'Department: ' + this.mainData.checkup.department,
        styles: { halign: 'left', fontSize: 8 }
      },
      {
        content: 'Doctor Name: Dr. ' + this.mainData.doctor.first_name + ' ' + this.mainData.doctor.last_name,
        styles: { halign: 'left', fontSize: 8 }
      },
      {
        content: 'Channel: ' + this.mainData.checkup.channel,
        styles: { halign: 'left', fontSize: 8 }
      }
    ];
    headerArray.push(headerDetailsArrayRow3);

    if (this.mainData.checkup.department === 'IPD') {
      const headerDetailsArrayRow4 = [
        {
          content:
            'Check In Date: ' + this.datepipe.transform(this.mainData.checkup.check_in_date, Constants.DATE_FORMAT) ||
            '',
          styles: { halign: 'left', fontSize: 8 }
        },
        {
          content:
            'Check Out Date: ' + this.datepipe.transform(this.mainData.checkup.check_out_date, Constants.DATE_FORMAT) ||
            '',
          styles: { halign: 'left', fontSize: 8 }
        }
      ];
      headerArray.push(headerDetailsArrayRow4);
    }

    autoTable(doc, {
      body: headerArray,
      theme: 'plain',
      startY: 50, // Adjust this value to reduce top margin
      columnStyles: { 0: { cellWidth: 60 }, 1: { cellWidth: 60 }, 2: { cellWidth: 60 } }
    });

    // Medicines table
    if (tempArray.length > 0) {
      autoTable(doc, {
        head: [['#', 'Medicine', 'Dose', 'Duration-Instructions']],
        body: tempArray.map(({ index, medicine, dose, durationIns }) => [index, medicine, dose, durationIns]),
        theme: 'striped',
        headStyles: { fillColor: '#3b21db', fontSize: 8 },
        columnStyles: {
          0: { cellWidth: 10 }, // Width for the index column
          1: { cellWidth: 70 }, // Width for the Medicine column
          2: { cellWidth: 40 }, // Width for the Dose column
          3: { cellWidth: 70 } // Width for the Duration-Instructions column
        }
      });
    } else {
      autoTable(doc, {
        body: [
          [
            {
              content:
                "Prescription is unavailable for this visit as it has either been communicated orally by the doctor, or the purpose of the visit didn't warrant any prescription.",
              styles: {
                halign: 'center',
                cellWidth: 'auto',
                fontSize: 8
              }
            }
          ]
        ],
        theme: 'plain'
      });
    }

    if (this.medicalHistory) {
      const vitalArray = [];

      // Add Vitals header
      vitalArray.push([
        { content: 'Vitals', styles: { halign: 'center', fontSize: 12, fontStyle: 'bold' }, colSpan: 2 }
      ]);

      // Add vital data in two-column format
      const vitalsData = [
        [
          'Weight (kg): ' + (this.mainData.purpose_of_visit[0].medical_history.weight || 'N/A'),
          'Temperature (Fahrenheit): ' + (this.mainData.purpose_of_visit[0].medical_history.temperature || 'N/A')
        ],
        [
          'Heart Rate: ' + (this.mainData.purpose_of_visit[0].medical_history.heart_rate || 'N/A'),
          'Pulse: ' + (this.mainData.purpose_of_visit[0].medical_history.pulse || 'N/A')
        ],
        [
          'Respiratory Rate: ' + (this.mainData.purpose_of_visit[0].medical_history.respiratory_rate || 'N/A'),
          'Others: ' + (this.mainData.purpose_of_visit[0].medical_history.others || 'N/A')
        ],
        ['Clinical Examination: ' + (this.mainData.purpose_of_visit[0].medical_history.health || 'N/A'), '']
      ];

      vitalsData.forEach((row, index) => {
        vitalArray.push(
          index === 3 // Special case for Clinical Examination
            ? [{ content: row[0], colSpan: 2, styles: { fontSize: 8, halign: 'left' } }]
            : [
                { content: row[0], styles: { fontSize: 8 } },
                { content: row[1], styles: { fontSize: 8 } }
              ]
        );
      });

      // Render table without borders
      autoTable(doc, { body: vitalArray, theme: 'grid', columnStyles: { 0: { cellWidth: 90 } } });
    }

    // Line Of Treatment
    if (this.lineOfTreatments) {
      const lotArray = [];
      const lotArray1 = [
        { content: 'Line Of Treatment:', styles: { halign: 'left', fontSize: 12, fontStyle: 'bold' } }
      ];
      const lotArray2 = [
        {
          content: 'P.E. Findings & Status:' + this.lineOfTreatments.pet_findings_status,
          styles: { halign: 'left', fontSize: 8 } // Font size 8 for the value
        }
      ];
      lotArray.push(lotArray1);
      lotArray.push(lotArray2);

      (this.lineOfTreatments.line_of_treatments || []).forEach((item, index) => {
        // Treatment Header
        lotArray.push([
          {
            content:
              'Treatment ' +
              index +
              1 +
              ': ' +
              (this.datepipe.transform(this.mainData.checkup.check_out_date, 'dd-MM-yyyy hh:mm a') || ''),
            styles: { halign: 'left', fontSize: 10 }
          }
        ]);
        const lotArray3 = [{ content: item.description, styles: { halign: 'left', fontSize: 8 } }];
        lotArray.push(lotArray3);
      });

      // Render table without borders
      autoTable(doc, { body: lotArray, theme: 'plain', columnStyles: { 0: { cellWidth: 'wrap' } } });
    }

    // Doctor's Note and Next Checkup Date
    if (
      this.mainData.checkup.doctor_notes ||
      (this.mainData.checkup &&
        this.mainData.checkup.next_checkup_date &&
        this.mainData.checkup.next_checkup_date.length > 0)
    ) {
      let docNOote_and_nxtchkup = [];

      if (this.mainData.checkup.next_checkup_date && this.mainData.checkup.next_checkup_date.length > 0) {
        docNOote_and_nxtchkup.push([
          {
            content:
              'Next Checkup Date: ' +
              this.datepipe.transform(
                this.mainData.checkup.next_checkup_date[0].next_checkup_date,
                Constants.DATE_FORMAT
              ),
            styles: { halign: 'left', textColor: 'black', fontSize: 7 }
          }
        ]);
      }

      if (this.mainData.checkup.doctor_notes) {
        docNOote_and_nxtchkup.push([
          {
            content: 'Doctor Notes: ' + this.mainData.checkup.doctor_notes,
            styles: { halign: 'left', textColor: 'black', fontSize: 7 }
          }
        ]);
      }

      autoTable(doc, {
        body: docNOote_and_nxtchkup,
        theme: 'plain'
      });
    }

    // Footer notes
    autoTable(doc, {
      body: [
        [{ content: 'Powered by PawsNme', styles: { halign: 'center', textColor: '#1e26e6', fontSize: 8 } }],
        [
          {
            content: 'This is a Computer Generated Prescription, No Signature Required',
            styles: { halign: 'center', fontSize: 8 }
          }
        ]
      ],
      theme: 'plain'
    });

    // Output the document
    if (type === 'download') {
      doc.save('Prescription_' + this.mainData.prescription_no + '.pdf');
    } else if (type === 'print') {
      doc.autoPrint({ variant: 'non-conform' });
      const blob = doc.output('blob');
      window.open(URL.createObjectURL(blob));
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (window.innerWidth <= 700) {
      setTimeout(() => {
        this.actionButton('download');
      }, 700);
    }
  }
}
