<h1 mat-dialog-title class="text-center dialogHeader blueHeader text-white">Payment</h1>
<mat-dialog-content class="mb-4">
  <div class="amountBox">
    <div class="col-md-6">
      <p id="totalAmount">
        <b>
          To Pay:<span style="color: green">
            {{ paymentAmount | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN' }}</span
          >
        </b>
      </p>
    </div>
    <div class="col-md-6">
      <p id="toPay">
        <b>
          Pending:<span style="color: red">
            {{ pendingAmount | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN' }}</span
          >
        </b>
      </p>
    </div>
  </div>
  <div class="paymentType ml-2">
    <div class="payment-option mt-2 ml-4">
      <div class="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          [(ngModel)]="paymentModes.upi"
          value="UPI"
          id="upi"
          name="payment"
          class="custom-control-input"
          (change)="onCheckboxChange('upi')"
        />
        <label class="custom-control-label" for="upi">UPI</label>
      </div>
      <div class="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          [(ngModel)]="paymentModes.cash"
          value="CASH"
          id="cash"
          name="payment"
          class="custom-control-input"
          (change)="onCheckboxChange('cash')"
        />
        <label class="custom-control-label" for="cash">Cash</label>
      </div>
      <div class="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          [(ngModel)]="paymentModes.card"
          value="CARD"
          id="card"
          name="payment"
          class="custom-control-input"
          (change)="onCheckboxChange('card')"
        />
        <label class="custom-control-label" for="card">Card</label>
      </div>

      <div class="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          [(ngModel)]="paymentModes.netBanking"
          value="NET-BANKING"
          id="netbanking"
          name="payment"
          class="custom-control-input"
          (change)="onCheckboxChange('netBanking')"
        />
        <label class="custom-control-label" for="netbanking">Net Banking</label>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div class="pendingDiv col-md-12 mb-2" *ngIf="advanceAmount > 0">
      <label for="advanceAmount" class="toPay col-md-4 required">Advance Paid</label>
      <input
        type="text"
        class="form-control input-font pendingAmount discountBox col-md-3"
        id="advanceAmount"
        [(ngModel)]="advanceAmount"
        readonly
      />
    </div>
    <!-- UPI Amount  -->
    <ng-container *ngIf="paymentModes.upi">
      <div class="paymentDiv col-md-12 mb-2">
        <label for="pendingAmount" class="toPay col-md-4 required">UPI Amount</label>
        <input
          #UPIAmount
          allowType="DIGITSWTHDOT"
          appSelectText
          type="text"
          maxlength="10"
          class="form-control input-font pendingAmount discountBox col-md-3"
          id="upi-amount"
          placeholder="UPI Amount"
          [(ngModel)]="upiAmount"
          (keyup)="validateAmount('upi')"
        />
        <span
          type="button"
          class="btn btn-link ml-2 text-small"
          (click)="showReferenceInput = !showReferenceInput"
          *ngIf="!showReferenceInput"
        >
          Add More Info >>
        </span>
        <input
          *ngIf="showReferenceInput"
          appSelectText
          allowType="TEXTDIGITSPCL"
          type="text"
          maxlength="10"
          class="form-control input-font discountBox col-md-4 offset-md-1 extraBox"
          id="upiRef"
          placeholder="Reference No"
          [(ngModel)]="upiRef"
        />
      </div>
    </ng-container>
    <!-- Cash Amount -->
    <ng-container *ngIf="paymentModes.cash">
      <div class="paymentDiv col-md-12 mb-2">
        <label for="pendingAmount" class="toPay col-md-4 required">Cash Amount</label>
        <input
          allowType="DIGITSWTHDOT"
          appSelectText
          type="text"
          maxlength="10"
          class="form-control input-font pendingAmount discountBox col-md-3"
          id="cash-amount"
          placeholder="Cash Amount"
          [(ngModel)]="cashAmount"
          (keyup)="validateAmount('cash')"
        />
      </div>
    </ng-container>

    <!-- Card Amount -->
    <ng-container *ngIf="paymentModes.card">
      <div class="paymentDiv col-md-12 mb-2">
        <label for="pendingAmount" class="toPay col-md-4 required">Card Amount</label>
        <input
          appSelectText
          type="text"
          allowType="DIGITSWTHDOT"
          maxlength="10"
          class="form-control input-font pendingAmount discountBox col-md-3"
          id="card-amount"
          placeholder="Card Amount"
          [(ngModel)]="cardAmount"
          (keyup)="validateAmount('card')"
        />
        <span
          type="button"
          class="btn btn-link ml-2 text-small"
          (click)="showCardReferenceInput = !showCardReferenceInput"
          *ngIf="!showCardReferenceInput"
        >
          Add More Info >>
        </span>
        <input
          *ngIf="showCardReferenceInput"
          appSelectText
          allowType="TEXTDIGITSPCL"
          type="text"
          maxlength="10"
          class="form-control input-font discountBox col-md-4 offset-md-1 extraBox"
          id="cardNo"
          placeholder="Last 4 digits"
          [(ngModel)]="cardNo"
        />
      </div>
    </ng-container>

    <!-- Net Banking Amount -->
    <ng-container *ngIf="paymentModes.netBanking">
      <div class="paymentDiv col-md-12 mb-2">
        <label for="pendingAmount" class="toPay col-md-4 required pr-1 netbanking-amount">Net Banking Amount</label>
        <input
          appSelectText
          allowType="DIGITSWTHDOT"
          type="text"
          maxlength="10"
          class="form-control input-font pendingAmount discountBox col-md-3"
          id="netbanking-amount"
          placeholder="Net Banking Amount"
          [(ngModel)]="netBankingAmount"
          (keyup)="validateAmount('netBanking')"
        />
      </div>
    </ng-container>
    <ng-container>
      <div class="paymentDiv col-md-12 mb-2 mt-2 d-flex align-items-center">
        <label for="paymentnotes" class="topay col-md-4">Notes</label>
        <span
          type="button"
          class="btn btn-link ml-2 text-small"
          (click)="showNotesMoreInfo = !showNotesMoreInfo"
          *ngIf="!showNotesMoreInfo"
        >
          Add Notes >>
        </span>
        <textarea
          *ngIf="showNotesMoreInfo"
          name="paymentnotes"
          id="paymentnotes"
          class="form-control input-font discountBox col-md-8"
          rows="1"
          cols="30"
          maxlength="100"
          [(ngModel)]="notes"
        ></textarea>
      </div>
    </ng-container>
    <section *ngIf="invoiceId && invoiceId.length > 0 && paymentType === 'bill'" class="text-center pt-4">
      <input type="checkbox" id="checkboxSelection" [(ngModel)]="checkboxSelection" />
      <label for="checkboxSelection" class="text-info checkboxSelection ml-2">{{ confirmCheckboxLabel }}</label>
    </section>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button
      mat-button
      class="mat-raised-button mr-4 btn blankTurquoise btnWidth"
      (click)="dialogRef.close(false)"
      title="Click to cancel payment"
    >
      Cancel
    </button>
    <button
      mat-button
      class="mat-raised-button btn turquoiseBtn btnWidth"
      (click)="confirmPayment()"
      title="Click to confirm payment"
    >
      Confirm
    </button>
  </div>
</mat-dialog-actions>
