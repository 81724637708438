import { Component } from '@angular/core';
import { Constants } from '../constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  releaseVersion: string = Constants.RELEASE_VERSION;
  currentYear: number;

  constructor() {
    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
  }
}
