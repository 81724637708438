import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { GlobalResponse, Doctor } from 'src/app/shared-module/models';
import { PetCertificatesServiceService } from '../pet-certificates-service.service';
import { IMyDrpOptions } from 'mydaterangepicker';
import { Constants } from 'src/app/shared-module/constants';
import { ConfirmationDialogComponent } from 'src/app/shared-module/confirmation-dialog/confirmation-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ICertificate } from '../models/certificate';
import { CommonService } from 'src/app/shared-module/common-service.service';
@Component({
  selector: 'app-certificate-dashboard',
  templateUrl: './certificate-dashboard.component.html',
  styleUrls: ['./certificate-dashboard.component.scss']
})
export class CertificateDashboardComponent implements OnInit {
  isLoggedIn = true;
  doctor: Doctor;
  certificates: ICertificate[] = [
    { value: 'HEALTH_CERTIFICATE', label: 'Health Certificate' },
    { value: 'SURGERY_CERTIFICATE', label: 'Surgery Certificate' },
    { value: 'VACCINATION_CERTIFICATE', label: 'Vaccination Certificate' },
    { value: 'SPAYED_NEUTER_SURGERY_PROCEDURE_CERTIFICATE', label: 'Spayed Neuter Surgery Procedure Certificate' },
    { value: 'TRAVEL_CERTIFICATE', label: 'Travel Certificate' },
    { value: 'MICROCHIP_CERTIFICATE', label: 'Microchip Certficate' },
    { value: 'EUTHANASIA_CONSENT_FORM', label: 'Euthenesia Consent Form' }
  ];
  certificateList: any[] = [];
  doctorList: any[] = [];
  doctor_selected: any;
  pageNumber = 0;
  pageSize = 25;
  length;
  certificate_selected: any;
  search$ = new Subject<string>();
  search_String = '';
  dateRange: any;
  currentDate: Date = new Date();
  previousMonthDate: Date;
  pageSizeOptions: number[] = [25, 50, 100];
  @BlockUI() blockUI: NgBlockUI;
  private delaySubject = new Subject<string>();
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  myDateRangePickerOptions: IMyDrpOptions = {
    dateFormat: Constants.DATE_RANGE_FORMAT,
    disableSince: {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate() + 1
    },
    showClearDateRangeBtn: false,
    editableDateRangeField: false,
    openSelectorOnInputClick: true
  };

  constructor(
    private router: Router,
    private service: PetCertificatesServiceService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {
    this.previousMonthDate = new Date(this.currentDate);
    this.previousMonthDate.setMonth(this.previousMonthDate.getMonth() - 1);
    this.dateRange = {
      beginDate: {
        year: this.previousMonthDate.getFullYear(),
        month: this.previousMonthDate.getMonth() + 1,
        day: this.previousMonthDate.getDate()
      },
      endDate: {
        year: this.currentDate.getFullYear(),
        month: this.currentDate.getMonth() + 1,
        day: this.currentDate.getDate()
      }
    };

    this.search$.pipe(debounceTime(400), distinctUntilChanged()).subscribe(result => {
      if (result && result.length >= 2) {
        this.search_String = result.trim();
      } else {
        this.search_String = '';
      }
      this.categoryChanged(true);
    });
  }

  ngOnInit() {
    this.doctor = JSON.parse(localStorage.getItem('login'));
    this.doctorList = this.commonService.doctorList;
    if (!this.doctorList || this.doctorList.length < 1) {
      this.fetchStaff();
    } else {
      if (this.doctorList[0].name !== 'All') {
        this.doctorList.unshift({ name: 'All' });
      }
      this.doctor_selected = { name: 'All' };
      this.categoryChanged(true);
    }
  }

  fetchStaff() {
    this.blockUI.start(Constants.LOADER_FETCHING);
    this.commonService.fetchDoctorList().subscribe(
      (res: GlobalResponse) => {
        this.blockUI.stop();
        if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
          this.doctorList = res.result.doctor;
          this.doctorList.unshift(JSON.parse(localStorage.getItem('login')));
          this.commonService.doctorList = this.doctorList;
          if (this.doctorList && this.doctorList.length > 0) {
            for (const item of this.doctorList) {
              item.name = (item.salutation ? item.salutation : 'Dr.') + ' ' + item.first_name + ' ' + item.last_name;
            }
          }
          this.doctorList.unshift({ name: 'All' });
          this.doctor_selected = { name: 'All' };
          this.categoryChanged(true);
        }
      },
      error => {
        this.blockUI.stop();
        this.toastr.error(error);
      }
    );
  }

  formatCertificateName(certificateName: string): string {
    return this.service.formatCertificateName(certificateName);
  }

  categoryChanged(resetNumber: boolean) {
    if (resetNumber) {
      this.pageNumber = 0;
      this.pageSize = 25;
    }
    let doctorID = '';
    if (this.doctor_selected && this.doctor_selected != null) {
      if (this.doctor_selected._id) {
        doctorID = this.doctor_selected._id;
      } else {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.doctorList.length; i++) {
          if (this.doctorList[i]._id) {
            doctorID += this.doctorList[i]._id;
            if (i + 1 < this.doctorList.length) {
              doctorID += ',';
            }
          }
        }
      }
    }
    this.blockUI.start();
    this.service
      .fetchCertificateList(
        this.pageNumber,
        this.pageSize,
        this.dateRange.beginDate,
        this.dateRange.endDate,
        this.search_String ? this.search_String : '',
        doctorID,
        this.certificate_selected ? this.certificate_selected : ''
      )
      .subscribe(
        (res: GlobalResponse) => {
          this.blockUI.stop();
          if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
            this.certificateList = res.result.allPetCertificateDetails;
            this.length = res.result.totalCount;
          }
        },
        (error: any) => {
          this.blockUI.stop();
          this.toastr.error(error);
        }
      );
  }

  navigateToCreateCertificate() {
    this.router.navigate(['/certificate/create']);
  }

  pageEvent(event) {
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.categoryChanged(false);
  }

  changeDate(event: any) {
    this.pageNumber = 0;
    this.pageSize = 50;
    this.paginator.firstPage();
    if (event) {
      this.dateRange = event;
    }
    this.categoryChanged(false);
  }

  onChange(value) {
    this.pageNumber = 0;
    this.pageSize = 25;
    if (this.doctor_selected && this.doctor_selected.name) {
      this.categoryChanged(false);
    }
  }

  viewAction(item: any, action: string) {
    if (item && action === 'delete') {
      const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, { disableClose: true });
      confirmDialogRef.componentInstance.confirmMessage = Constants.CONFIRMATION_DELETE_ITEM;

      confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.blockUI.start();
          this.service.deleteCertificate(item._id).subscribe(
            (res: GlobalResponse) => {
              this.blockUI.stop();
              if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
                this.toastr.success(item.certificateType + ' removed successfully');
                this.categoryChanged(false);
              }
            },
            (error: any) => {
              this.blockUI.stop();
              this.toastr.error(error);
            }
          );
        }
      });
    }
  }
  // routing for editing certificate
  editCertificate(item: any) {
    this.router.navigate(['/certificate/edit', item._id]);
  }

  openCertificate(certificateId: string) {
    const certificateUrl = this.router.createUrlTree(['/petcertificate/', certificateId]).toString();
    window.open(certificateUrl, '_blank');
  }
}
