import { environment } from '../../environments/environment';

export class Constants {
  public static MAIN_ENV_URL = '';

  // API
  public static URL_DASH = '/';
  public static DOCTOR_API = 'doctor/';
  public static SLASH_CLINIC_API = '/clinic/';
  public static CLINIC_API = 'clinic/';
  public static INVOICE_API = 'invoice/';
  public static STORE_INVOICE_API = 'store-invoice/';
  public static PRESCRIPTION_API = 'prescription/';
  public static PAYMENT_API = '/payment';
  public static PAYMENTS_API = '/payments';
  public static SERVICE_CONSUMPTIONS_API = '/service-consumptions';
  public static CUSTOMER_API = 'customer/';
  public static SLASH_INVOICES = '/invoices';
  public static CLINIC_PAYMENTS = '/clinicPayments';
  public static SLASH_INVOICE = '/invoice';
  public static SLASH_INVOICE_SLASH_API = '/invoice/';
  public static SLASH_CHECKUP_SLASH = '/checkup/';
  public static SLASH_CHECKUP_API = 'checkup/';
  public static SLASH_PRESCRIPTIONS = '/prescriptions';
  public static SLASH_REMIND_PAYMENT = '/remindPayment';
  public static PET_API = '/pet/';
  public static DOCTOR_LOGIN_API = 'doctor/login';
  public static DOCTOR_ACTIVATE_API = 'doctor/activate/';
  public static DOCTOR_REGISTER_API = 'doctor/register';
  public static RESET_PASSWORD_API = 'doctor/resetPassword/';
  public static DOCTOR_LEAD_API = 'doctor/doctorLead';
  public static DOCTOR_CUSTOMER_API = 'doctor/customer/';
  public static DOCTOR_CUSTOMER_DETAILS_API = 'doctor/customer/details/';
  public static DOCTOR_CHANGE_PASSWORD_API = 'doctor/changePassword';
  public static DOCTOR_FORGOT_PASSWORD_API = 'doctor/forgotPassword';
  public static DOCTOR_REGISTER_CUSTOMER_API = 'doctor/registerCustomer';
  public static DOCTOR_REGISTER_CUSTOMER_WITH_PET_API = 'doctor/registerCustomerWithPet';
  public static DOCTOR_CHECKUP_API = 'doctor/checkup/';
  public static DOCTOR_CHECKUP_INTIALIZE_API = 'doctor/checkup/initialize';
  public static DOCTOR_CHECKUP_REINTIALIZE_API = 'doctor/checkup/reInitialize';
  public static DOCTOR_VACCINATION_API = '/vaccination/';
  public static LAB_REPORTS_API = '/lab/';
  public static DOCTOR_LOGOUT_API = 'doctor/logout';
  public static MEDICAL_HISTORY_LIST_API = '/medicalHistory/';
  public static MEDICINE_RECORDS_API = '/medicine/';
  public static DRUG_LIST_API = 'drug/';
  public static SUMMARY_DRUG_API = 'summary/drug/';
  public static GET_DRUGS_API = 'getdrugs/';
  public static SLASH_DRUG_SLASH_API = '/drug/';
  public static OTHERSERVICES_LIST_API = '/otherServices/';
  public static SURGERY_RECORDS_API = '/surgery/';
  public static DOCTOR_UPLOAD_PROFILE_IMAGE_API = 'doctor/uploadProfileImage/';
  public static DOCTOR_UPLOAD_DIGITAL_SIGNATURE_API = 'doctor/uploadDigitalSignatureImage/';
  public static DOCTOR_OTP_GENERATE_API = 'doctor/otp/generateOtp';
  public static DOCTOR_OTP_VERIFY_API = 'doctor/otp/verifyOtp';
  public static DOCTOR_UPLOAD_VACCINATION_API = 'doctor/uploadVaccinationReport/';
  public static DOCTOR_UPLOAD_LAB_REPORT_API = 'doctor/uploadLabReport/';
  public static DOCTOR_UPLOAD_SURGERY_REPORT_API = 'doctor/uploadSurgeyReport/';
  public static DOCTOR_UPLOAD_VACCINATION_REPORT_API = 'doctor/uploadVaccinationReport/';
  public static DOCTOR_UPLOAD_MEDICAL_HISTORY_API = 'doctor/uploadMedicalHistoryImage/';
  public static DOCTOR_BREEDS_API = 'doctor/breeds/';
  public static DOCTOR_PET_SPECIES_API = 'doctor/pet/species';
  public static DOCTOR_SETTINGS_API = '/settings';
  public static DOCTOR_FETCH_PETS_API = 'doctor/customer/pets';
  public static SLASH_DASHBOARD_API = '/dashboard';
  public static SLASH_BATCHES_API = '/batches';
  public static SLASH_BATCH_SLASH_API = '/batch/';
  public static SLASH_BATCH_API = '/batch';
  public static CONSUME_API = '/consume';
  public static DOCTOR_LIST_API = '/listDoctor';
  public static LIST_API = '/list';
  public static ADD_DOCTOR_API = '/addDoctor';
  public static UPDATE_DOCTOR_API = '/updateDoctor';
  public static DRUG_API = 'drug';
  public static DRUG_BULK_UPLOAD_API = 'drug/uploadDrugList/';
  public static NOTES_LIST_API = '/listNotes';
  public static NOTES_ADD_API = '/addNote';
  public static LINE_OF_TREATMENT_ADD_API = '/addLineOfTreatment';
  public static NOTES_API = '/note/';
  public static SUBMIT_API = '/submit';
  public static SAVE_API = '/save';
  public static LINE_OF_TREATMENT_API = '/lineOfTreatment/';
  public static CHECKUP_PENDING_API = 'checkups/PENDING';
  public static CHECKUP_COMPLETED_API = '/checkups/COMPLETED';
  public static DRUG_DASHBOARD_API = '/drugDashboard';
  public static EXPIRING_BATCHES_API = '/expiringBatches';
  public static VERIFY_PIN_API = '/verifyPin';
  public static CHECKUP_REMINDER_LIST_API = '/checkup-reminders';
  public static VACCINE_REMINDER_LIST_API = '/vaccine-reminders';
  public static CANCEL_CHECKUP_API = '/cancel-checkup';
  public static FOLLOW_UP_API = '/follow-up';
  public static BELOW_THRESHOLD_API = '/getreorderdrugs';
  public static STORE_GET_STORE_CUSTOMER_API = 'store/getstorecustomer';
  public static STORE_GET_PO_DETAILS_API = 'store/getPODetails';
  public static STORE_PENDING_PO_LIST_API = 'store/pendingpurchaseorders';
  public static STORE_COMPLETED_PO_LIST_API = 'store/completedorders';
  public static STORE_CANCEL_PO_API = 'store/cancelPO';
  public static STORE_ADD_MEDICINE_API = 'store/add-medicine';
  public static STORE_CREATE_CUSTOMER_API = 'store/createstorecustomer';
  public static STORE_DELETE_MEDICINE_API = 'store/deleteMedicine';
  public static STORE_EDIT_PRICE_API = 'store/edit-price ';
  public static STORE_UPDATE_BATCH_API = 'store/updateBatch';
  public static STORE_SUBMIT_PO_API = 'store/submitPO';
  public static STORE_UPDATE_PAYMENT_SUMMARY_API = 'store/updatePaymentSummary';
  public static STORE_UPDATE_INVOICE_CUSTOMER_API = 'store/updateInvoiceCustomer';
  public static STORE_UPDATE_PENDING_PAYMENT_API = 'store/updatePendingPayment';
  public static STORE_LIST_PAYMENT_SUMMARY_API = 'store/listPaymentSummary';
  public static STORE_PAYMENTS_API = '/store-payments';
  public static INVENTORY_CONSUMPTION_LIST_API = '/drug-consumptions';
  public static SERVICE_CONSUMPTION_LIST_API = '/service-consumptions';
  public static PURPOSES_API = '/purposes';
  public static PURPOSE_API = '/purpose';
  public static PROCEDURE_API = '/procedure';
  public static DIAGNOSTIC_API = '/diagnostic';
  public static INHERIT_PURPOSE_API = '/inherit-purpose';
  public static INHERIT_PROCEDURE_API = '/inherit-procedure';
  public static INHERIT_DIAGNOSTIC_API = '/inherit-diagnostic';
  public static PURPOSE_UPLOAD_API = '/purpose-upload';
  public static PROCEDURE_UPLOAD_API = '/procedure-upload';
  public static DIAGNOSTIC_UPLOAD_API = '/diagnostic-upload';
  public static NOTIFIER_API = '/notifier';
  public static REMOVE_DOC_API = '/remove-doc';
  public static ADD_MEDICINE_API = '/add-medicine';
  public static ADVANCE_PAYMENT_API = '/advance-payment';
  public static UPLOAD_CLINIC_LOGO_API = '/upload-clinic-logo/';
  public static BARCODES_API = 'barcodes';
  public static AVAILABLE_API = '/available';
  public static CHECKUP_COMPLETE_API = '/checkup-complete';
  public static LINE_ITEM_API = 'line-item/';
  public static STORE_LINE_ITEM_API = 'store/line-item/';
  public static STORE_PET_API = 'store-pet';
  public static AUDIT_LOGS_API = 'logs/';
  public static TRANSFER_PET_API = 'transfer/pet/';
  public static HSN_API = 'hsn';
  public static HSN_SLASH_API = 'hsn/';
  public static EXPENSE_TYPE_API = 'expenseType/';
  public static EXPENSE_VENDOR_API = 'expenseVendor/';
  public static EXPENSES_API = 'expenses/';
  public static CLINICAL_EXPENSE_API = 'clinicalExpense/';
  public static HSN_SUMMARY_API = 'summary/';

  // URL
  public static HOME_URL = '/home';
  public static LOGIN_URL = '/login';
  public static DASHBOARD_URL = '/dashboard';
  public static CHANGE_PASSWORD_URL = '/profile/changepassword';
  public static MANAGE_STAFF_URL = '/profile/managestaff';
  public static MANAGE_DASHBOARD_URL = '/profile/mydashboard';
  public static EDIT_DOCTOR_PROFILE_URL = '/profile/editprofile';
  public static EDIT_BILL_URL = '/inpatient/editbill/';
  public static PROFILE_DASHBOARD_URL = '/profile/dashboard';
  public static EXPENSE_DASHBOARD_URL = '/expense/expenses';
  public static VENDORS_LIST_URL = '/expense/vendors';
  public static INVENTORY_DASHBOARD_URL = '/inventory/dashboard';
  public static INVENTORY_STOCK_MOVEMENT_URL = '/inventory/stockmovement';
  public static INVENTORY_AUDIT_LOG_URL = '/inventory/audit';
  public static INVENTORY_REORDER_URL = '/inventory/reorder';
  public static INVENTORY_ANALYSIS_URL = '/inventory/analysis';
  public static HSN_LIST_URL = '/hsn/list';
  public static HSN_SUMMARY_URL = '/hsn/summary';
  // public static CLINIC_REGISTRATION_URL = '/clinic-registration';
  public static PET_DASHBOARD_URL = '/pet/dashboard';
  public static PET_REGISTRATION_URL = '/pet/registration';
  public static MOBILE_VERIFICATION_URL = '/mobile-verification';
  public static CREATE_PET_URL = '/pet/create';
  public static DETAILS_URL = 'details';
  public static EDIT_CATEGORY_URL = 'editdrugcategory';
  public static PET_CHECKUP_URL = '/pet/checkup';
  public static INPATIENT_PENDING_LIST_URL = '/inpatient/pendinglist';
  public static INPATIENT_COMPLETED_LIST_URL = '/inpatient/completedlist';
  public static INPATIENT_REMINDER_LIST_URL = '/inpatient/reminderlist';
  public static STORE_COMPLETED_LIST_URL = '/store/completedlist';
  public static STORE_PENDING_LIST_URL = '/store/ongoinglist';
  public static STORE_CREATE_ORDER_URL = '/store/createorder';
  public static STORE_PAYMENTS_URL = '/store/storepayments';
  public static STORE_INVOICES_URL = '/store/invoices';
  public static STORE_TRANSACTION_LIST_URL = '/store/transactionlist';
  public static TRANSACTIONS_CLINIC_URL = '/transactions/clinic';
  public static TRANSACTIONS_SETTLEMENTS_URL = '/transactions/settlements';
  public static TRANSACTIONS_VET_URL = '/transactions/vet';
  public static TRANSACTIONS_SALES_URL = '/transactions/sales';
  public static TRANSACTIONS_STORE_LIST_URL = '/transactions/store';
  public static OTP_VERIFICATION_URL = '/pet/otp-verification';
  public static BILLING_PRESCRIPTION_HISTORY_URL = '/billing/prescriptionhistory';
  public static BILLING_HISTORY_URL = '/billing/history';
  public static BILLING_DASHBOARD_URL = '/billing/dashboard';
  public static BULK_UPLOAD_URL = '/bulkupload';
  public static POV_BULK_UPLOAD_URL = environment.bulkUploadUrl + 'POV-upload-template.xlsx';
  public static INV_BULK_UPLOAD_URL = environment.bulkUploadUrl + 'Inventory-Upload-Template.xlsx';
  public static DIAG_BULK_UPLOAD_URL = environment.bulkUploadUrl + 'diagnostic-upload-template.xlsx';
  public static PROC_BULK_UPLOAD_URL = environment.bulkUploadUrl + 'procedure-upload-template.xlsx';
  public static RELEASE_VERSION = environment.releaseVersion;
  public static CLINIC_REGISTRATION_URL = 'https://pawsnme.com/doctor-registration/';

  // Constants
  public static STATUS_200 = '200';
  public static STATUS_SUCCESS = 'Success';
  public static STATUS_ACCEPT = 'ACCEPT';
  public static STATUS_REJECT = 'REJECT';
  public static STATUS_SUBMITTED = 'SUBMITTED';
  public static STATUS_SAVED = 'SAVED';
  public static STATUS_GENERATED = 'GENERATED';
  public static STATUS_PARTIALLY_PAID = 'PARTIALLY_PAID';
  public static SUBSTRACT = 'SUBSTRACT';
  public static ADD = 'ADD';
  public static DELETE = 'DELETE';
  public static LABEL_ERROR = 'Error';
  public static LABEL_WARNING = 'Warning';
  public static LABEL_SUCCESS = 'Success';
  public static CURRENCY = 'currency';
  public static PERCENT = 'percent';
  public static UPLOAD_REPORT = 'Upload Report';
  public static DOCTOR = 'doctor';
  public static PET_ID = 'pet_id';
  public static LABEL_SEARCH = 'search';
  public static LABEL_KEYWORD = 'keyword';
  public static VACCINATION_DEWORMING_VALUE = 'VACCINATION-DEWORMING';
  public static VACCINATION_VALUE = 'VACCINATION';
  public static MEDICAL_HISTORY_VALUE = 'medicalHistory';
  public static DIAGNOSTIC_LAB_VALUE = 'LAB';
  public static DIAGNOSTIC_VALUE = 'DIAGNOSTIC';
  public static PROCEDURE_SURGERY_VALUE = 'SURGERY';
  public static SYRUP_VALUE = 'SYRUP';
  public static DROPS_VALUE = 'DROPS';
  public static POWDER_SHAMPOO_VALUE = 'POWDER-SHAMPOO';
  public static TABLET_VALUE = 'TABLET';
  public static ALL_INVENTORY_VALUE = 'ALL';
  public static CAN_FOOD_VALUE = 'CAN-FOOD';
  public static SYRUP_SUPPLEMENT_VALUE = 'SYRUP-SUPPLEMENT';
  public static OINTMENT_SPRAY_VALUE = 'OINTMENT-SPRAY';
  public static CAPSULE_VALUE = 'CAPSULE';
  public static SUPPOSITORY_VALUE = 'SUPPOSITORY';
  public static SOFTGEL_CAPSULE_VALUE = 'SOFTGEL-CAPSULE';
  public static DRY_SYRUP_VALUE = 'DRY-SYRUP';
  public static TOPICAL_POWDER_VALUE = 'TOPICAL-POWDER';
  public static ORAL_SUPPLEMENTARY_VALUE = 'ORAL-SUPPLEMENTARY';
  public static SPOT_ON_VALUE = 'SPOT-ON';
  public static OINTMENT_VALUE = 'OINTMENT';
  public static LOTION_VALUE = 'LOTION';
  public static GEL_VALUE = 'GEL';
  public static ORAL_DROPS_VALUE = 'ORAL-DROPS';
  public static EYE_DROPS_VALUE = 'EYE-DROPS';
  public static EAR_DROPS_VALUE = 'EAR-DROPS';
  public static ORAL_SPRAY_VALUE = 'ORAL-SPRAY';
  public static TOPICAL_SPRAY_VALUE = 'TOPICAL-SPRAY';
  public static CATEGORY = 'category';
  public static FOLLOWUP_VISIT_VALUE = 'FOLLOW UP VISIT';
  public static DISPOSABLE_VALUE = 'DISPOSABLE';
  public static MEDICINE_VALUE = 'MEDICINE';
  public static INJECTABLE_VALUE = 'INJECTABLE';
  public static CONSUMABLE_VALUE = 'CONSUMABLE';
  public static PRODUCT_VALUE = 'PRODUCT';
  public static SERVICES_VALUE = 'SERVICES';
  public static OTHER_VALUE = 'OTHER';
  public static PURPOSE_OF_VISIT_VALUE = 'PURPOSE-OF-VISIT';
  public static PROCEDURE_VALUE = 'PROCEDURE';
  public static PATIENT_EXAMINATION = 'Patient Examination';
  public static OTHER_SERVICES_VALUE = 'otherServices';
  public static LAB_VALUE = 'LAB';
  public static SURGERY_VALUE = 'SURGERY';
  public static PAYMENT_CASH = 'CASH';
  public static PAYMENT_CARD = 'CARD';
  public static PAYMENT_UPI = 'UPI';
  public static DATE_FORMAT = 'dd-mm-yyyy';
  public static USER_MANAGEMENT = 'UserManagement';
  public static INVENTORY_MANGEMENT = 'InventoryMangement';
  public static CHECKUP = 'Checkup';
  public static DASHBOARD = 'Dashboard';
  public static TRANSACTIONS = 'Transactions';
  public static PHONE_CONSULTATION = 'PhoneConsultation';
  public static STAFF_MANGEMENT = 'StaffMangement';
  public static E_TOKEN = 'E-Token';
  public static ONGOING_CHECKUP = 'ONGOING CHECKUP';
  public static FILE_NAME_PET = 'pet_';
  public static MOBILE_NUMBER = 'mobile_number';
  public static REPORT_VALUE = 'REPORT';
  public static LOADER_LOADING = 'Loading...';
  public static LOADER_UPDATING = 'Updating...';
  public static LOADER_SEARCHING = 'Searching...';
  public static LOADER_FETCHING = 'Fetching...';

  // Query params
  public static QUERY_PARAMS_ITEMS_PER_PAGE = 'itemsPerPage';
  public static QUERY_PARAMS_PAGE_NUMBER = 'pageNumber';
  public static QUERY_PARAMS_DOCTORS = 'doctors';
  public static QUERY_PARAMS_DOCTOR = 'doctor';
  public static QUERY_PARAMS_CLINIC = 'clinic';
  public static QUERY_PARAMS_Q = 'q';
  public static QUERY_PARAMS_SEARCH = 'search';
  public static QUERY_PARAMS_FROM_DATE = 'fromDate';
  public static QUERY_PARAMS_TO_DATE = 'toDate';
  public static QUERY_PARAMS_MODE = 'mode';
  public static QUERY_PARAMS_CHANNEL = 'channel';
  public static QUERY_PARAMS_CATEGORY = 'category';
  public static QUERY_PARAMS_TYPE = 'type';
  public static QUERY_PARAMS_STATUS = 'status';
  public static QUERY_PARAMS_PAGE_FILTER = 'page_filter';
  public static QUERY_PARAMS_SUB_PAGE_FILTER = 'sub_page_filter';
  public static QUERY_PARAMS_EXPENSE_TYPE = 'expenseTypeId';
  public static QUERY_PARAMS_EXPENSE_VENDOR = 'expenseVendorId';

  // File Extensions
  public static FILE_TYPE_SUPPORTED =
    '.doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt,.pdf,.png,.gif,.jpeg,.jpg, .rtf, .eml, .msg';
  public static EXCEL_FILE_TYPE_SUPPORTED = '.xls, .xlsx, .csv';
  public static EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  public static JPEG_EXTENSION = '.jpeg';
  public static PNG_EXTENSION = '.png';
  public static EXCEL_EXTENSION = '.xlsx';
  public static IMAGE_FILE_TYPE_SUPPORTED = '.png,.gif,.jpeg,.jpg';
  public static PDF_IMAGE_FILE_TYPE_SUPPORTED = '.pdf,.png,.jpeg,.jpg';

  // Info Message
  public static INFO_COPIED = 'Copied: ';

  // Error Message
  public static ERROR_INVALID_MOBILE = 'Invalid Mobile Number';
  public static ERROR_NUMBER_OFDAYS = 'Please enter number of days';
  public static ERROR_QUANTITY = 'Please enter number of items';
  public static ERROR_PRICE_MISSING_TABLET = 'Price is missing for Tablet';
  public static ERROR_PRICE_MISSING_SYRUP = 'Price is missing for Syrup';
  public static ERROR_PRICE_MISSING_VACCINATION = 'Price is missing for Vaccination / Deworming';
  public static ERROR_PRICE_MISSING_DIAGNOSTIC = 'Price is missing for Diagnostic Procedure';
  public static ERROR_PRICE_MISSING_PROCEDURE = 'Price is missing for Procedure';
  public static ERROR_PRICE_MISSING_OINTMENT = 'Price is missing for Ointment / Spray';
  public static ERROR_PRICE_MISSING_SHAMPOO = 'Price is missing for Shampoo / Powder';
  public static ERROR_PRICE_MISSING_DROPS = 'Price is missing for Drops';
  public static ERROR_PRICE_MISSING_DISPOSABLE = 'Price is missing for Disposables';
  public static ERROR_PRICE_MISSING_INJECTABLE = 'Price is missing for Injectable';
  public static ERROR_PRICE_MISSING_CONSUMABLE = 'Price is missing for Consumable';
  public static ERROR_PRICE_MISSING_OTHERS = 'Price is missing for Others field';
  public static ERROR_PRICE_MISSING_LAB = 'Price is missing for Lab Report';
  public static ERROR_PRICE_MISSING_SURGERY = 'Price is missing for Surgery';
  public static ERROR_DAILY_DOSAGE_TABLET = 'Daily dosage missing';
  public static ERROR_DAILY_DOSAGE_SYRUP = 'Daily dosage missing';
  public static ERROR_CHOOSE_TABLET = 'Choose Tablets';
  public static ERROR_CHOOSE_SYRUP = 'Choose Syrups';
  public static ERROR_CHOOSE_VACCINATION = 'Choose the vaccination';
  public static ERROR_CHOOSE_DIAGNOSTIC = 'Please choose the Diagnostic Procedure';
  public static ERROR_CHOOSE_PROCEDURE = 'Please choose the Procedure';
  public static ERROR_CHOOSE_OINTMENT = 'Choose Ointment / Spray';
  public static ERROR_CHOOSE_SHAMPOO = 'Choose Shampoo / Powder';
  public static ERROR_CHOOSE_DROPS = 'Choose Drops';
  public static ERROR_CHOOSE_DISPOSABLE = 'Choose Disposables';
  public static ERROR_CHOOSE_INJECTABLE = 'Choose Injectables';
  public static ERROR_CHOOSE_CONSUMABLE = 'Choose Products';
  public static ERROR_CHOOSE_OTHER = 'Choose Services';
  public static ERROR_CHOOSE_PURPOSE_VISIT = 'Choose Purpose Of Visit';
  public static ERROR_ENTER_LAB_REMARKS = 'Please enter Lab Remarks';
  public static ERROR_ENTER_SURGERY_REMARKS = 'Please enter Surgery Remarks';
  public static ERROR_CHOOSE_VACCINATION_DATE = 'Choose the next vaccination date';
  public static ERROR_CHOOSE_CHECKUP_DATE = 'Choose the next follup visit date';
  public static ERROR_CHOOSE_PAYMENT_AMOUNT = 'Please choose the payment method and amount paid';
  public static ERROR_RECORD_ALREADY_ADDED = 'Record already added';
  public static ERROR_PERMISSION_NEEDED = 'You need permission to perform this action';
  public static ERROR_TECHNICAL = 'There is some Technical error. Please contact System Administrator.';
  public static ERROR_SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later.';
  public static ERROR_INTERNET1_MESSAGE = 'Something went wrong, Please contact system administrator';
  public static ERROR_INTERNET2_MESSAGE = 'OR Please check your Internet';
  public static ERROR_INVALID_BARCODE = 'Please enter valid Barcode';
  public static ERROR_ITEM_UNAVAILABLE = ' is unavailable currently. Please try again later';
  public static SYSTEM_ERROR_MESSAGE = 'System Error';

  // Warning Message
  public static WARNING_NO_PET_PRESENT = 'No Pet Found';
  public static WARNING_NO_CUSTOMER_FOUND = 'Customer Not Found';
  public static WARNING_IS_OUT_OF_STOCK = ' is out of stock';
  public static WARNING_WRONG_OTP = ' Enter 6 digit OTP sent to the registered mobile number';
  public static WARNING_ITEM_NOT_AVAILABLE = 'Item not available. Please add new stock in Inventory before billing.';
  public static WARNING_ITEM_ALREADY_ADDED = 'Item already added';
  public static WARNING_ITEM_NOT_FOUND = 'No Item Found';
  public static WARNING_DISCOUNT_PRICE_GREATER = "Discount can't be greater than item price";
  public static WARNING_HSN_DELETE_IN_USE =
    'HSN is in use in Inventory. Remove the inventory item before deleting HSN.';

  // Success Message
  public static SUCCESS_PAYMENT_DONE = 'Payment confirmed';
  public static SUCCESS_STAFF_ADDED = 'Staff Added Successfully';
  public static SUCCESS_STATUS_CHANGED = 'Status Changed Successfully';
  public static SUCCESS_DOCTOR_STATUS_CHANGED = 'Doctor Status Changed Successfully';
  public static SUCCESS_CHECKUP_DETAILS_CHANGED = 'Checkup Details updated Successfully';
  public static SUCCESS_INVOICE_DELETED = 'Invoice Deleted Successfully';
  public static SUCCESS_ITEM_DELETED = 'Item Deleted Successfully';
  public static SUCCESS_ITEM_UPDATED = 'Item Updated Successfully';
  public static SUCCESS_ITEM_ADDED = 'Item Added Successfully';
  public static SUCCESS_ITEM_CREATED = 'Item Created Successfully';
  public static SUCCESS_CHECKUP_UPDATED = 'Check-up details Updated';
  public static SUCCESS_ADDED = 'Added Successful';
  public static SUCCESS_INOVICE_GENERATED = 'Invoice generated Successful';
  public static SUCCESS_OTP_GENERATED = 'OTP generated and sent to the registered mobile number';
  public static SUCCESS_OTP_VERIFIED = 'Success, Add a new pet now.';
  public static SUCCESS_OTP_RESENT = 'OTP Resent';
  public static SUCCESS_OTP_SUCCESS = 'OTP verified successfully';
  public static SUCCESS_PET_ADDED = 'Pet added Successfully';
  public static SUCCESS_PET_UPDATED = 'Pet updated Successfully';
  public static SUCCESS_DOCTOR_CALL_TOGGLE = 'Unavailable for Phone Consultation now';
  public static SUCCESS_DOCTOR_CALL_TOGGLE1 = 'Available for Phone Consultation now';
  public static SUCCESS_CLINIC_MODE = 'You are in clinic consultation mode';
  public static SUCCESS_PHONE_MODE = 'You are in phone consultation mode';
  public static SUCCESS_DEPARTMENT_CHANGED = 'Department Changed Successfully';
  public static SUCCESS_SAVED = 'Saved Successful';
  public static SUCCESS_PIN_VERIFIED = 'Doctor PIN verified Successfully';
  public static SUCCESS_LOGOUT = 'You have now logged out Successfully';
  public static SUCCESS_CUSTOMER_REGISTRATION = 'Customer has been registered Successfully';
  public static SUCCESS_DELETED = ' deleted successfully';
  public static SUCCESS_ADV_PAYMENT_ADDED = 'Advance Payment Added Successfully';
  public static SUCCESS_ADV_PAYMENT_SETTLED = 'Extra Advance Payment Settled Successfully';
  public static SUCCESS_IMAGE_UPLOADED = 'Image Uploaded Successfully';
  public static SUCCESS_NOTIFICATIN_SENT = 'Notification sent successfully';
  public static SUCCESS_PET_TRANSFER = 'Pet Transferred successfully';

  // Confirmation Message
  public static CONFIRMATION_WITHOUT_PICTURE = 'Want to Continue without taking Picture?';
  public static CONFIRMATION_DELETE_ITEM = 'Are you sure to delete the Item?';
  public static CONFIRMATION_DELETE_INVOICE =
    'Bills without any item can only be deleted. Are you sure to delete the Invoice?';
  public static CONFIRMATION_CONSULTATION_COMPLETED_ITEM =
    'This pet examination is now completed by an other doctor. Any changes made hereafter would be considered as a new consultation.';
  public static CONFIRMATION_WARNING_CONSULTATION_EXAMINED =
    'This pet is currently being examined by an other doctor. Any changes you make here would reflect an ongoing process.';
  public static CONFIRMATION_SUBMIT_CHECKUP = 'Any changes made hereafter would not reflect in the check-up summary';
  public static CONFIRMATION_SUBMIT_CHECKUP_PHONE = 'You are about to complete a phone consultation checkup.';
  public static CONFIRMATION_SUBMIT_CHECKUP_PHONE1 =
    'The Pet owner would be able to view the prescription, post payment of';
  public static CONFIRMATION_TOGGLE_CONSULTATION_CHECKUP =
    'Are you sure about changing the consultation type? If yes, ongoing consultation data will be lost.';
  public static CONFIRMATION_DELETE_ITEM_NA = 'Deleting Item as quantity not available';
  public static CONFIRMATION_ADVANCE_AMOUNT_MESSAGE =
    'Advance amount paid by the customer is higher than the Total amount. Please Refund back Rs.';

  // dd/mm/yyyy date format to be used
}
