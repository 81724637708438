import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../shared-module/common-service.service';
import { ConfirmationDialogComponent } from '../../shared-module/confirmation-dialog/confirmation-dialog.component';
import { Constants } from '../../shared-module/constants';
import { AuthenticationService } from '../../shared-module/services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  @BlockUI() blockUI: NgBlockUI;
  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err && err.message.indexOf('Http failure response for') > -1 && err.error.type === 'error') {
          if (!this.commonService.errorMessageStatus) {
            this.commonService.errorMessageStatus = true;
            this.openErrorBox();
          }
        } else {
          this.commonService.errorMessageStatus = false;
        }
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          location.reload();
        }
        let error = '';
        if (err.error) {
          if (err.error.errors) {
            error = err.error.errors;
            if (err.error.errors.Details) {
              error = err.error.errors.Details;
            }
          } else if (err.error.result) {
            error = err.error.result;
          } else if (err.error.message) {
            error = err.error.message;
          }
        } else if (err.errors) {
          error = err.errors;
        } else if (err.message) {
          error = err.message;
        }
        this.blockUI.stop();
        return throwError(error);
      })
    );
  }

  openErrorBox() {
    const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true
    });
    confirmDialogRef.componentInstance.confirmMessage = Constants.ERROR_INTERNET1_MESSAGE;
    confirmDialogRef.componentInstance.confirmMessage1 = Constants.ERROR_INTERNET2_MESSAGE;
    confirmDialogRef.componentInstance.headerText = Constants.SYSTEM_ERROR_MESSAGE;
    confirmDialogRef.componentInstance.showCancelButton = false;
    confirmDialogRef.componentInstance.showSuccessButton = true;
    confirmDialogRef.componentInstance.successButton = 'Reload Page';

    confirmDialogRef.afterClosed().subscribe(result => {
      window.location.reload();
      confirmDialogRef.close();
    });
  }
}
