<h1 mat-dialog-title class="text-center blueHeader dialogHeader text-white">Confirm</h1>
<mat-dialog-content class="mb-4">
  <p class="text-center">Are you sure you want to cancel this checkup?</p>
  <div class="mt-3">
    <div class="col-md-12 mb-2 mt-3">
      <label for="reason" class="toPay col-md-3 required">Reason</label>
      <select name="reason" id="reason" class="form-control input-font category d-inline col-md-9" [(ngModel)]="reason">
        <option value="Select">Select</option>
        <option value="Cancelling as Pet visited just for enquiry">Cancelling as Pet visited just for enquiry</option>
        <option value="Others">Others</option>
      </select>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button
      mat-button
      title="Click to stay on Current Checkup"
      class="mat-raised-button mr-4 btn blankTurquoise btnWidth"
      (click)="dialogRef.close(false)"
    >
      Don't Cancel
    </button>
    <button
      mat-button
      title="Click to Cancel the Current Checkup"
      class="mat-raised-button btn turquoiseBtn btnWidth btn-sm updateButton"
      (click)="cancelCheckup()"
    >
      Cancel
    </button>
  </div>
</mat-dialog-actions>
