import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared-module/common-service.service';
import { Constants } from 'src/app/shared-module/constants';
import { CustomerStore, Doctor, GlobalResponse } from 'src/app/shared-module/models';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class StorePaymentDialogComponent implements OnInit, AfterViewInit {
  payment = Constants.PAYMENT_CASH;
  paymentModes = {
    cash: false,
    card: false,
    upi: true,
    netBanking: false
  };

  cashAmount = 0;
  cardAmount = 0;
  upiAmount = 0;
  netBankingAmount = 0;

  upiRef: string;
  cardNo: string;
  notes: string;

  showReferenceInput = false;
  showCardReferenceInput = false;
  showNotesMoreInfo = false;

  public paymentAmount: number;
  public payingAmount: number;
  public pendingAmount: any;
  public grandTotal: number;
  public invoiceId: string;
  public doctor: Doctor;
  public navigateURL: string;
  public paymentType: string;
  public mobile: any;
  public poNumber: any;
  public confirmCheckboxLabel = '';
  public checkboxSelection = false;
  customerDetails: CustomerStore;
  disableConfirmButton = false;
  disablePayLaterButton = true;
  oldCustomer = false;
  @ViewChild('UPIAmount', { static: false }) private elementRef: ElementRef;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public dialogRef: MatDialogRef<StorePaymentDialogComponent>,
    private commonService: CommonService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    this.paymentAmount = this.grandTotal;
    this.pendingAmount = this.paymentAmount;
    if (this.paymentType === 'store') {
      this.disableConfirmButton = true;
    }
    if (!this.customerDetails) {
      this.customerDetails = {
        email: '',
        firstname: '',
        lastname: ''
      };
    }
    if (this.mobile && this.mobile.toString().length === 10) {
      this.oldCustomer = true;
      this.disablePayLaterButton = false;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.paymentModes.upi && this.elementRef.nativeElement) {
        this.elementRef.nativeElement.focus();
      }
    }, 350);
  }

  onCheckboxChange(mode: string) {
    const isAnySelected = Object.values(this.paymentModes).some(value => value);

    if (!isAnySelected) {
      this.paymentModes[mode] = true;
    }
    if (!this.paymentModes.cash) {
      this.cashAmount = 0;
      this.changeAmount('cash');
    }
    if (!this.paymentModes.card) {
      this.cardAmount = 0;
      this.changeAmount('card');
    }
    if (!this.paymentModes.upi) {
      this.upiAmount = 0;
      this.changeAmount('upi');
    }
    if (!this.paymentModes.netBanking) {
      this.netBankingAmount = 0;
      this.changeAmount('netBanking');
    }
  }

  changeAmount(changedField: string) {
    this.disableConfirmButton = this.disablePayLaterButton = false;

    // Calculate the total entered across all payment methods
    const totalEntered = Object.keys(this.paymentModes).reduce((total, mode) => {
      return total + Number(this[`${mode}Amount`] || 0);
    }, 0);

    // If the total entered exceeds the grandTotal
    if (totalEntered >= this.grandTotal) {
      const excessAmount = totalEntered - this.grandTotal;

      // If the entered amount exceeds grandTotal, reset only the changed field
      switch (changedField) {
        case 'cash':
          this.cashAmount = Math.max(this.cashAmount - excessAmount, 0);
          break;
        case 'card':
          this.cardAmount = Math.max(this.cardAmount - excessAmount, 0);
          break;
        case 'upi':
          this.upiAmount = Math.max(this.upiAmount - excessAmount, 0);
          break;
        case 'netBanking':
          this.netBankingAmount = Math.max(this.netBankingAmount - excessAmount, 0);
          break;
      }

      this.pendingAmount = 0; // Set pending amount to zero once total is met
    } else {
      // Calculate pending amount normally when the total is less than grandTotal
      this.pendingAmount = (this.grandTotal - totalEntered).toFixed(2);

      // Mobile validation and disabling buttons based on 'store' payment type
      if (this.paymentType === 'store' && (!this.mobile || (this.mobile && this.mobile.toString().length < 10))) {
        this.disableConfirmButton = this.disablePayLaterButton = true;
      }
    }
  }

  changeData(type: string) {
    if (type === 'mobile' && this.mobile && this.mobile.toString().length === 10) {
      this.searchOwner();
    } else {
      this.disableConfirmButton = this.disablePayLaterButton = true;
      if (this.mobile.toString().length === 10) {
        this.disableConfirmButton = this.disablePayLaterButton = false;
      }
    }
  }

  confirmPayment() {
    if (!this.payment || this.payment.length === 0) {
      this.toastr.error(Constants.ERROR_CHOOSE_PAYMENT_AMOUNT);
      return;
    }

    const payments = this.collectPaymentDetails();
    this.payingAmount = Number(payments.reduce((acc, item) => acc + Number(item.paidAmount), 0));

    if (!this.payingAmount || this.payingAmount <= 0) {
      this.toastr.error(Constants.ERROR_CHOOSE_PAYMENT_AMOUNT);
      return;
    }

    this.blockUI.start(); // Start blocking UI during payment processing

    // Prepare request object
    const req_obj = {
      doctor: this.doctor._id,
      clinic: this.doctor.clinics[0].clinic._id,
      invoice: this.invoiceId,
      paidAmount: this.payingAmount,
      payments,
      notes: this.notes,
      phone: this.mobile,
      first_name: this.customerDetails.firstname ? this.customerDetails.firstname : '',
      last_name: this.customerDetails.lastname ? this.customerDetails.lastname : '',
      email: this.customerDetails.email ? this.customerDetails.email : ''
    };

    // Handle 'store' payment type
    if (this.paymentType === 'store') {
      this.processStorePayment(req_obj);
    }
    // Handle 'storePayment' payment type
    else if (this.paymentType === 'storePayment') {
      this.processStorePendingPayment(req_obj);
    }
  }

  // Collect the payment details from different payment modes
  collectPaymentDetails(): {
    paidAmount: number;
    paymentMethod: string;
    transactionReferenceNo?: string;
    lastCardFourDigits?: string;
  }[] {
    const payments = [];

    if (this.cashAmount > 0) {
      payments.push({
        paidAmount: this.cashAmount,
        paymentMethod: 'CASH'
      });
    }
    if (this.cardAmount > 0) {
      payments.push({
        paidAmount: this.cardAmount,
        paymentMethod: 'CARD',
        lastFourCardDigits: this.cardNo
      });
    }
    if (this.upiAmount > 0) {
      payments.push({
        paidAmount: this.upiAmount,
        paymentMethod: 'UPI',
        transactionReferenceNo: this.upiRef
      });
    }
    if (this.netBankingAmount > 0) {
      payments.push({
        paidAmount: this.netBankingAmount,
        paymentMethod: 'NETBANKING'
      });
    }

    return payments;
  }

  // Process 'store' payment type
  processStorePayment(req_obj: any) {
    this.commonService.updateStorePayment(req_obj).subscribe(
      _ => {
        if (this.pendingAmount > 0) {
          this.updateInvoiceCustomer(); // Handle customer details update for pending payment
        } else {
          this.completePayment();
        }
      },
      error => this.handleError(error)
    );
  }

  // Process 'storePayment' payment type
  processStorePendingPayment(req_obj: any) {
    this.commonService.updateStorePendingPayment(req_obj).subscribe(
      (res: GlobalResponse) => {
        this.blockUI.stop();
        this.toastr.success(Constants.SUCCESS_PAYMENT_DONE);
        this.dialogRef.close(true);

        if (this.navigateURL && this.navigateURL.length > 1) {
          this.router.navigate([this.navigateURL]);
        }
      },
      error => this.handleError(error)
    );
  }

  // Update invoice customer details for pending payments
  updateInvoiceCustomer() {
    const req_obj2 = {
      invoice: this.invoiceId,
      phonenumber: this.mobile,
      firstname: this.customerDetails.firstname,
      lastname: this.customerDetails.lastname,
      email: this.customerDetails.email
    };

    this.commonService.updateInvoiceCustomer(req_obj2).subscribe(
      _ => {
        this.completePayment();
        this.toastr.success('Customer details updated to invoice');
      },
      error => this.handleError(error)
    );
  }

  // Complete payment flow
  completePayment() {
    this.blockUI.stop();
    this.toastr.success(Constants.SUCCESS_PAYMENT_DONE);

    if (this.checkboxSelection && this.paymentType === 'store') {
      this.dialogRef.close(true);
      const url = this.router.serializeUrl(this.router.createUrlTree(['/storeinvoice', this.invoiceId]));
      window.open(url, '_blank');
    } else {
      this.dialogRef.close(true);
    }

    if (this.navigateURL && this.navigateURL.length > 1) {
      this.router.navigate([this.navigateURL]);
    }
  }

  // Handle errors in API calls
  handleError(error: any) {
    this.blockUI.stop();
    this.dialogRef.close(true);
    this.toastr.error(error.message || 'An error occurred');
  }

  searchOwner() {
    this.blockUI.start();
    this.customerDetails.firstname = '';
    this.customerDetails.lastname = '';
    this.customerDetails.email = '';
    this.disableConfirmButton = this.disablePayLaterButton = true;
    this.oldCustomer = false;
    this.commonService
      .getCustomerInfo(this.mobile, this.doctor._id, this.doctor.clinics[0].clinic._id, this.poNumber)
      .subscribe(
        (res: GlobalResponse) => {
          this.blockUI.stop();
          if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
            this.customerDetails = res.result.PO.customer;
            this.disableConfirmButton = this.disablePayLaterButton = false;
            if (
              this.customerDetails.firstname &&
              this.customerDetails.firstname.length > 1 &&
              this.customerDetails.lastname &&
              this.customerDetails.lastname.length > 1
            ) {
              this.oldCustomer = true;
            }
          }
        },
        error => {
          this.blockUI.stop();
          this.oldCustomer = false;
          this.disableConfirmButton = this.disablePayLaterButton = true;
          error.message ? this.toastr.warning(error.message) : this.toastr.warning(error);
          if (error === 'Customer not found') {
            this.toastr.warning('Please continue to enter name and email to add customer');
          }
          //   const registeraddItemDialogRef = this.dialog.open(RegisterCustomerDialogComponent, {
          //     disableClose: true
          //   });
          //   registeraddItemDialogRef.componentInstance.phone = this.mobile;
          //   registeraddItemDialogRef.componentInstance.po_no = this.poNumber;

          //   registeraddItemDialogRef.afterClosed().subscribe(result => {
          //     if (result) {
          //       result.doctor = this.doctor._id;
          //       result.clinic = this.doctor.clinics[0].clinic._id;
          //       result.PO = this.poNumber;
          //       this.service.createStoreCustomer(result).subscribe((res: GlobalResponse) => {
          //         this.blockUI.stop();
          //         if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
          //           this.toastr.success(Constants.SUCCESS_CUSTOMER_REGISTRATION);
          //           this.customerDetails = res.result.PO.customer;
          //           this.poDetails = res.result.PO;
          //         }
          //       });
          //     }
          //   });
          // }
        }
      );
  }
}
