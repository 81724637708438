<div class="row">
  <div class="col-lg-8 col-md-6 bg-login">
    <div>
      <img class="logo" alt="Pawsnme" src="./assets/images/Logo white option_380_100.svg" />
    </div>
    <div class="main-p">
      <p>Let us make the world</p>
      <p>a better place for pets</p>
    </div>
    <div class="sub-p">
      <p>Looking for a cloud-based solution to manage your clinic?</p>
    </div>
    <div class="register-div">
      <button type="button" (click)="gotoRegister()" class="btn btn-primary-outline register">Register Now</button>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 mobile-login">
    <div class="login">
      <p *ngIf="!showForgetPassword">Login</p>
      <p *ngIf="showForgetPassword" style="font-size: smaller">Forgot Password</p>
    </div>
    <div class="container" *ngIf="!showForgetPassword">
      <div class="col-md-12">
        <input
          [(ngModel)]="emailId"
          [ngModelOptions]="{ standalone: true }"
          (keyup.enter)="login()"
          maxlength="50"
          placeholder="Doctor's EmailId"
          class="login-input col-md-10 col-10"
          type="text"
          value=""
        />
        <div class="col-md-2 col-2 underline fa fa-user-md"></div>
      </div>
      <div class="col-md-12">
        <input
          placeholder="Password"
          class="login-input col-md-10 col-10"
          type="password"
          id="inputPassword"
          maxlength="30"
          #inputPassword
          [(ngModel)]="password"
          (keyup.enter)="login()"
          [ngModelOptions]="{ standalone: true }"
          minlength="6"
        />
        <label
          for="inputPassword"
          class="fa input-icon"
          [ngClass]="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"
          (click)="
            inputPassword.type = inputPassword.type == 'password' ? 'text' : 'password'; showPassword = !showPassword
          "
        ></label>

        <div class="col-md-2 col-2 underline fa fa-key"></div>
      </div>
      <div class="col-md-12">
        <div class="col-md-7 d-inline-block pt-4">
          <!-- <label>Forgot your password? </label> -->
          <a class="resetLink ml-1" (click)="forgotPassword(false)">Forgot your password? </a>
        </div>
        <div class="d-inline-block pt-5 col-md-5">
          <button (click)="login()" type="button" class="login-button fa fa-arrow-right btn"></button>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="showForgetPassword">
      <div class="col-md-12">
        <input
          [(ngModel)]="emailIdForgetPwd"
          [ngModelOptions]="{ standalone: true }"
          placeholder="Enter your email ID"
          class="login-input col-md-10"
          type="text"
          inputText
          allowType="TEXTEMAIL"
          value=""
          maxlength="60"
        />
        <div class="underline fa fa-user-md pl-3"></div>
      </div>
      <div class="col-md-12">
        <div class="col-md-7 d-inline-block">
          <a class="resetLink ml-1" (click)="forgotPassword(true)">Remember Login?</a>
        </div>
        <div class="d-inline-block pt-5 col-md-5">
          <button (click)="resetPassword()" type="button" class="login-button btn fa fa-arrow-right"></button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
