import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Doctor } from 'src/app/shared-module/models';
import { Title } from '@angular/platform-browser';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ICreateCertificateResp } from '../models/create-certificate-req-resp';
import { PetCertificatesServiceService } from '../pet-certificates-service.service';

@Component({
  selector: 'app-certificate-template',
  templateUrl: './certificate-template.component.html',
  styleUrls: ['./certificate-template.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CertificateTemplateComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  isLoggedIn = true;
  doctorProfile: any = {};
  doctor: Doctor;
  certificateId: string;
  certificateType: string;
  certificateDetails: ICreateCertificateResp = {} as ICreateCertificateResp;
  digitalSignature: string;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private service: PetCertificatesServiceService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.certificateId = params.get('certificateID');

      this.service.getCertificateById(this.certificateId).subscribe(details => {
        this.certificateDetails = details.result;
        this.certificateType = this.certificateDetails.certificateType;
        this.titleService.setTitle(this.service.formatCertificateName(this.certificateType));
        this.digitalSignature = this.certificateDetails.doctor.digital_signature_url;
      });
    });
  }

  async loadImage(url: string): Promise<string> {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = () => reject('Image load failed');
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      return null; // Return null or a fallback image URL
    }
  }

  async actionButton(type: string) {
    let base64LogoImageString;
    let base64SignImageString;
    if (this.certificateDetails && this.certificateDetails.clinic.logo_url) {
      base64LogoImageString = await this.loadImage(this.certificateDetails.clinic.logo_url);
    }
    if (this.digitalSignature) {
      base64SignImageString = await this.loadImage(this.digitalSignature);
    }

    const content = document.getElementById('print_area');

    if (content) {
      if (type === 'download') {
        html2canvas(content, { scale: 3, useCORS: true }).then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');

          const margin = 10;
          const position = 10;
          const pageWidth = 210 - 2 * margin;
          const imgWidth = pageWidth;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);

          if (base64LogoImageString) {
            pdf.addImage(base64LogoImageString, 'PNG', 165, 10, 30, 15);
          }

          if (base64SignImageString) {
            pdf.addImage(base64SignImageString, 'PNG', 162, imgHeight - 30, 30, 15);
          }

          pdf.save(`${this.certificateDetails.certificateType}_${this.certificateDetails.pet.name}.pdf`);
        });
      } else if (type === 'print') {
        const printWindow = window.open('', '_blank');
        if (printWindow) {
          printWindow.document.write(`
            <html>
              <head>
                <title>Print Certificate</title>
                <style>
                  ${document.head.innerHTML}
                </style>
              </head>
              <body>
                ${content.outerHTML}
                <script>
                  window.onload = function () {
                    window.print();
                    window.onafterprint = function () { window.close(); };
                  };
                </script>
              </body>
            </html>
          `);
          printWindow.document.close();
        }
      }
    }
  }
}
