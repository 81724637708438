<div class="actions">
  <button class="mb-3 oval btn payBlue printButton fa fa-print" (click)="actionButton('print')">
    &nbsp;&nbsp;Print
  </button>
  <!-- <button class="mb-3 oval btn payBlue downloadButton fa-download fa" (click)="actionButton('download')">
    &nbsp;&nbsp;Download
  </button> -->
</div>

<div class="certificate-container" id="print_area" #print_area *ngIf="certificateDetails">
  <div class="header">
    <div class="clinicDetails">
      <span class="clinicName">
        {{ certificateDetails?.clinic?.name }}
      </span>
      <div class="clinic-group address">
        <div>
          <span class="address header-text" style="font-size: 15px">{{ certificateDetails?.clinic?.address }}</span>
        </div>

        <div class="gst-licence">
          <div class="gst-line-item">
            <span class="mobile header-icon font-weight-bold" style="font-size: 1rem">Ph: </span>
            <span class="mobile header-text" style="font-size: 1rem">{{ certificateDetails?.clinic?.phone_no }}</span>
            <span class="mobile header-text" style="font-size: 1rem">
              / {{ certificateDetails?.clinic?.phone_no2 || '9876543211' }}
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;||&nbsp;&nbsp;&nbsp;&nbsp;
            <span class="header-text" style="font-size: 1rem"
              ><b>W:</b> {{ certificateDetails?.clinic?.website || 'www.pawsnme.com' }}</span
            >
            &nbsp;&nbsp;&nbsp;&nbsp;||&nbsp;&nbsp;&nbsp;&nbsp;
            <span class="header-text" style="font-size: 1rem"
              ><b>E:</b> {{ certificateDetails?.clinic?.email_id || 'pawsnme@gmail.com' }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="clinicLogo" *ngIf="certificateDetails?.clinic?.logo_url">
      <img [src]="certificateDetails?.clinic?.logo_url" alt="clinic_logo" style="width: 200px" />
    </div>
  </div>
  <div class="main">
    <div class="cert-contents">
      <div class="dynamic-content">
        <app-euthenesia-consent-form
          [certificateDetails]="certificateDetails"
          *ngIf="certificateType === 'EUTHANASIA_CONSENT_FORM'"
        ></app-euthenesia-consent-form>
        <app-health-certificate
          [certificateDetails]="certificateDetails"
          *ngIf="certificateType === 'HEALTH_CERTIFICATE'"
        ></app-health-certificate>
        <app-microchip-certificate
          [certificateDetails]="certificateDetails"
          *ngIf="certificateType === 'MICROCHIP_CERTIFICATE'"
        ></app-microchip-certificate>
        <app-pet-travel-certificate
          [certificateDetails]="certificateDetails"
          *ngIf="certificateType === 'TRAVEL_CERTIFICATE'"
        ></app-pet-travel-certificate>
        <app-spayed-certificate
          [certificateDetails]="certificateDetails"
          *ngIf="certificateType === 'SPAYED_NEUTER_SURGERY_PROCEDURE_CERTIFICATE'"
        ></app-spayed-certificate>
        <app-surgery-certificate
          [certificateDetails]="certificateDetails"
          *ngIf="certificateType === 'SURGERY_CERTIFICATE'"
        ></app-surgery-certificate>
        <app-vaccination-certificate
          [certificateDetails]="certificateDetails"
          *ngIf="certificateType === 'VACCINATION_CERTIFICATE'"
        ></app-vaccination-certificate>
      </div>
      <div class="parent-signature" *ngIf="certificateType === 'EUTHANASIA_CONSENT_FORM'">
        <div class="signature">
          <p class="font-weight-bold">Signature of Owner</p>
          <p class="font-weight-bold">Signature of Whitness</p>
        </div>
      </div>
      <div class="parent-signature" *ngIf="certificateType === 'SURGERY_CERTIFICATE'">
        <div class="parent-signature-text font-weight-bold float-right">Pet Parent Signature</div>
        <br />
        <br />
      </div>
      <div *ngIf="certificateType === 'EUTHANASIA_CONSENT_FORM' || certificateType === 'SURGERY_CERTIFICATE'">
        <p class="parent-acknowledgement">
          By signing this document, I acknowledge that I am the rightful owner of
          <em>{{ certificateDetails?.pet.name }}</em> or that I possess full authority to make all necessary decisions
          on behalf of the pet.
        </p>
      </div>
      <div class="date-sign">
        <div class="text-left">
          <p class="date"><strong>Dated: </strong>{{ certificateDetails?.updatedAt | date: 'dd-MM-yyyy' }}</p>
          <p class="date" *ngIf="certificateDetails?.certificateNumber">
            <strong>Certificate No: </strong>{{ certificateDetails?.certificateNumber }}
          </p>
        </div>
        <div class="text-center">
          <img
            [src]="digitalSignature"
            alt="digital-sign"
            class="sign-image"
            *ngIf="
              certificateDetails.addSignature &&
              certificateDetails.addSignature === true &&
              certificateDetails?.clinic?.logo_url
            "
          />
          <div class="sign-image" *ngIf="!certificateDetails?.addSignature"></div>
          <p class="vet-sign"><strong>Vetrinarian Signature/Stamp</strong></p>
        </div>
      </div>
    </div>
  </div>
  <app-powered-by-footer></app-powered-by-footer>
</div>
