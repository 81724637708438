<h1 mat-dialog-title class="text-center dialogHeader blueHeader text-white">Payment store</h1>
<mat-dialog-content class="mb-2">
  <div class="amountBox mb-2">
    <div class="col-md-6">
      <div id="totalAmount">
        <b>
          To Pay:<span style="color: green">
            {{ paymentAmount | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN' }}</span
          >
        </b>
      </div>
    </div>
    <div class="col-md-6">
      <div id="toPay">
        <b>
          Pending:<span style="color: red">
            {{ pendingAmount | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN' }}</span
          >
        </b>
      </div>
    </div>
  </div>
  <div class="paymentType">
    <div class="payment-option mt-1 ml-4">
      <div class="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          [(ngModel)]="paymentModes.upi"
          value="UPI"
          id="upi"
          name="payment"
          class="custom-control-input"
          (change)="onCheckboxChange('upi')"
        />
        <label class="custom-control-label" for="upi">UPI</label>
      </div>
      <div class="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          [(ngModel)]="paymentModes.cash"
          value="CASH"
          id="cash"
          name="payment"
          class="custom-control-input"
          (change)="onCheckboxChange('cash')"
        />
        <label class="custom-control-label" for="cash">Cash</label>
      </div>
      <div class="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          [(ngModel)]="paymentModes.card"
          value="CARD"
          id="card"
          name="payment"
          class="custom-control-input"
          (change)="onCheckboxChange('card')"
        />
        <label class="custom-control-label" for="card">Card</label>
      </div>

      <div class="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          [(ngModel)]="paymentModes.netBanking"
          value="NET-BANKING"
          id="netbanking"
          name="payment"
          class="custom-control-input"
          (change)="onCheckboxChange('netBanking')"
        />
        <label class="custom-control-label" for="netbanking">Net Banking</label>
      </div>
    </div>
  </div>

  <div class="mt-3">
    <!-- UPI Amount -->
    <ng-container *ngIf="paymentModes.upi">
      <div class="paymentDiv col-md-12 mb-2 flex">
        <label for="pendingAmount" class="toPay col-md-4 flex required">UPI Amount</label>
        <input
          #UPIAmount
          appSelectText
          inputText
          allowType="DIGITSWTHDOT"
          type="text"
          maxlength="10"
          class="form-control input-font pendingAmount discountBox col-md-3"
          id="upi-amount"
          placeholder="UPI Amount"
          [(ngModel)]="upiAmount"
          (keyup)="changeAmount('upi')"
        />
        <span
          type="button"
          class="btn btn-link ml-2 text-small"
          (click)="showReferenceInput = !showReferenceInput"
          *ngIf="!showReferenceInput"
        >
          Add More Info >>
        </span>
        <input
          *ngIf="showReferenceInput"
          appSelectText
          allowType="TEXTDIGITSPCL"
          type="text"
          maxlength="10"
          class="form-control input-font discountBox col-md-4 offset-md-1 extraBox"
          id="additionalReference"
          placeholder="Reference No"
          [(ngModel)]="upiRef"
        />
      </div>
    </ng-container>

    <!-- Cash Amount -->
    <ng-container *ngIf="paymentModes.cash">
      <div class="paymentDiv col-md-12 mb-2">
        <label for="pendingAmount" class="toPay col-md-4 required">Cash Amount</label>
        <input
          appSelectText
          inputText
          allowType="DIGITSWTHDOT"
          type="text"
          maxlength="10"
          class="form-control input-font pendingAmount discountBox col-md-3"
          id="cash-amount"
          placeholder="Cash Amount"
          [(ngModel)]="cashAmount"
          (keyup)="changeAmount('cash')"
        />
      </div>
    </ng-container>

    <!-- Card Amount -->
    <ng-container *ngIf="paymentModes.card">
      <div class="paymentDiv col-md-12 mb-2">
        <label for="pendingAmount" class="toPay col-md-4 required">Card Amount</label>
        <input
          appSelectText
          inputText
          allowType="DIGITSWTHDOT"
          type="text"
          maxlength="10"
          class="form-control input-font pendingAmount discountBox col-md-3"
          id="card-amount"
          placeholder="Card Amount"
          [(ngModel)]="cardAmount"
          (keyup)="changeAmount('card')"
        />
        <span
          type="button"
          class="btn btn-link ml-2 text-small"
          (click)="showCardReferenceInput = !showCardReferenceInput"
          *ngIf="!showCardReferenceInput"
        >
          Add More Info >>
        </span>
        <input
          *ngIf="showCardReferenceInput"
          appSelectText
          allowType="TEXTDIGITSPCL"
          type="text"
          maxlength="10"
          class="form-control input-font discountBox col-md-4 offset-md-1 extraBox"
          id="cardReferenceNo"
          placeholder="Last 4 digits"
          [(ngModel)]="cardNo"
        />
      </div>
    </ng-container>

    <!-- Net Banking Amount -->
    <ng-container *ngIf="paymentModes.netBanking">
      <div class="paymentDiv col-md-12 mb-2">
        <label for="pendingAmount" class="toPay col-md-4 required pr-1 netbanking-amount">Net Banking Amount</label>
        <input
          appSelectText
          inputText
          allowType="DIGITSWTHDOT"
          type="text"
          maxlength="10"
          class="form-control input-font pendingAmount discountBox col-md-3"
          id="netbanking-amount"
          placeholder="Net Banking Amount"
          [(ngModel)]="netBankingAmount"
          (keyup)="changeAmount('netBanking')"
        />
      </div>
    </ng-container>

    <ng-container>
      <div class="paymentDiv col-md-12 mb-2 mt-2 d-flex align-items-center notes-div">
        <label for="paymentnotes" class="toPay col-md-4">Notes</label>
        <span
          type="button"
          class="btn btn-link ml-2 text-small"
          (click)="showNotesMoreInfo = !showNotesMoreInfo"
          *ngIf="!showNotesMoreInfo"
        >
          Add Notes >>
        </span>
        <textarea
          *ngIf="showNotesMoreInfo"
          name="payment-notes"
          id="paymentnotes"
          class="form-control input-font discountBox col-md-8"
          rows="1"
          cols="30"
          maxlength="100"
          [(ngModel)]="notes"
          placeholder="Enter Notes"
        ></textarea>
      </div>
      <!-- <div class="p-6 col-md-12 d-flex justify-content-center"> -->

      <!-- </div> -->
    </ng-container>

    <ng-container *ngIf="paymentType === 'store'">
      <div class="paymentDiv col-md-12 mb-2 notes-div">
        <label for="mobile" class="col-md-4" [ngClass]="{ required: pendingAmount > 0 }">Mobile Number</label>
        <input
          type="text"
          inputText
          allowType="DIGITSWTHDOT"
          class="form-control input-font paymentAmount discountBox col-md-8"
          id="mobile"
          placeholder="Enter Mobile Number"
          [(ngModel)]="mobile"
          maxlength="10"
          (keyup)="changeData('mobile')"
        />
      </div>
      <ng-container *ngIf="mobile && mobile.length === 10">
        <div class="paymentDiv col-md-12 mb-2">
          <label for="firstname" class="col-md-4">First Name</label>
          <input
            type="text"
            class="form-control input-font paymentAmount discountBox col-md-8"
            id="firstname"
            placeholder="First Name"
            [(ngModel)]="customerDetails.firstname"
            [disabled]="oldCustomer ? true : null"
            (keyup)="changeData('firstname')"
          />
        </div>
        <div class="paymentDiv col-md-12 mb-2">
          <label for="lastname" class="col-md-4">Last Name</label>
          <input
            type="text"
            class="form-control input-font paymentAmount discountBox col-md-8"
            id="lastname"
            placeholder="Last Name"
            [(ngModel)]="customerDetails.lastname"
            [disabled]="oldCustomer ? true : null"
            (keyup)="changeData('lastname')"
          />
        </div>
        <div class="paymentDiv col-md-12 mb-2">
          <label for="email" class="col-md-4">Email</label>
          <input
            type="text"
            maxlength="60"
            inputText
            placeholder="Email"
            allowType="TEXTEMAIL"
            class="form-control input-font paymentAmount discountBox col-md-8"
            id="email"
            [(ngModel)]="customerDetails.email"
          /></div
      ></ng-container>
    </ng-container>
    <section *ngIf="invoiceId && invoiceId.length > 0 && paymentType === 'store'" class="text-center pt-2">
      <input type="checkbox" id="checkboxSelection" [(ngModel)]="checkboxSelection" />
      <label for="checkboxSelection" class="checkboxSelection ml-2 text-info">{{ confirmCheckboxLabel }}</label>
    </section>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button
      mat-button
      class="mat-raised-button mr-4 btn blankTurquoise btnWidth"
      (click)="dialogRef.close(false)"
      title="Click to cancel payment"
    >
      Cancel
    </button>
    <button
      mat-button
      class="mat-raised-button btn turquoiseBtn btnWidth"
      (click)="confirmPayment()"
      [disabled]="disableConfirmButton ? true : null"
      title="Click to confirm payment"
    >
      Confirm
    </button>
  </div>
</mat-dialog-actions>
