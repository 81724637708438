import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { MatChipInputEvent, MatDialogRef } from '@angular/material';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../common-service.service';
import { Constants } from '../constants';
import { Drug, DrugRequest, GlobalResponse, IHSN } from '../models';

@Component({
  selector: 'app-create-drug-dialog',
  templateUrl: './create-drug-dialog.component.html',
  styleUrls: ['./create-drug-dialog.component.scss']
})
export class CreateDrugDialogComponent implements OnInit {
  public headerTitle = 'Add Item to Inventory';

  public doctorId: string;
  public clinicId: string;
  public drugId: string;
  public category: string;
  public rack_no: string;
  public manufacturer: string;
  public instructions: string;
  public seller_name: string;
  public batch_name: string;
  public unit: string;
  public qty = 0;
  public price = 0;
  // public mrp = 0;
  public hsn_selected: IHSN = {} as IHSN;
  public buyPrice = 0;
  public tax = 0;
  public searchedBarcode: any[] = [];
  public hsnList: IHSN[] = [];
  public barcodeSearched = false;
  nameData: any[] = [];
  public name_selected: any = {} as any;
  nameText: any;
  public barcodes: any[] = [];
  public requestType = '';

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];

  expiry_date: any;
  current_date: any = new Date();
  @BlockUI() blockUI: NgBlockUI;
  public myDatePickerOptionsExp: IMyDpOptions = {
    dateFormat: Constants.DATE_RANGE_FORMAT,
    editableDateField: false,
    openSelectorOnInputClick: true,
    showClearDateBtn: true,
    disableUntil: {
      year: this.current_date.getFullYear(),
      month: this.current_date.getMonth() + 1,
      day: this.current_date.getDate()
    }
  };

  constructor(
    public dialogRef: MatDialogRef<CreateDrugDialogComponent>,
    private service: CommonService,
    private toastr: ToastrService
  ) {
    this.doctorId = this.service.doctorValue._id;
    this.clinicId = this.service.doctorValue.clinics[0].clinic._id;
  }

  ngOnInit() {
    if (this.category && this.category.length > 0) {
      if (this.barcodeSearched || this.requestType === 'qtyadd') {
        this.categoryChanged(false);
      } else {
        this.categoryChanged(true);
      }
    }
    this.getHSNList();

    if (this.searchedBarcode && this.searchedBarcode.length > 0) {
      this.barcodes = this.searchedBarcode;
    }
  }

  getHSNList() {
    this.service.fetchHSNList(0, 9999).subscribe((res: GlobalResponse) => {
      if (res && res.message === Constants.STATUS_SUCCESS && res.result) {
        this.hsnList = res.result.hsn;
      }
    });
  }

  categoryChanged(changed: boolean = true) {
    this.nameData = [];
    if (changed) {
      this.name_selected = {} as any;
    }
    this.blockUI.start();
    this.service.getDrugList(this.category, 0, 0).subscribe(
      (res: GlobalResponse) => {
        this.blockUI.stop();
        if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
          this.nameData = res.result.drugs;
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.toastr.error(error);
      }
    );
  }

  addName = term => ({ name: term });

  onKeyPress(event: KeyboardEvent) {
    const allowedCharacters = /[a-zA-Z0-9\s\(\)+-._&><]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!allowedCharacters.test(inputChar)) {
      event.preventDefault();
    }
  }

  onNameChange() {
    if (this.name_selected && this.name_selected.name !== 'Select') {
      this.manufacturer = this.name_selected.manufacturer ? this.name_selected.manufacturer : '';
      this.instructions = this.name_selected.instructions ? this.name_selected.instructions : '';
      this.tax = this.name_selected.tax ? this.name_selected.tax : '';
      this.unit = this.name_selected.unit ? this.name_selected.unit : '';
      if (this.barcodeSearched && this.name_selected.barcodes) {
        const tempArray = this.searchedBarcode.concat(this.name_selected.barcodes);
        this.barcodes = tempArray.filter((item, pos) => tempArray.indexOf(item) === pos);
      } else if (this.barcodeSearched) {
        this.barcodes = this.barcodes ? this.barcodes : [];
      } else {
        this.barcodes = this.name_selected.barcodes ? this.name_selected.barcodes : [];
      }
      if (this.name_selected.seller_name) {
        this.seller_name = this.name_selected.seller_name;
      } else if (this.name_selected.supplier) {
        this.seller_name = this.name_selected.supplier;
      }
    } else {
      this.name_selected = { _id: null, name: 'Select' };
      this.manufacturer = '';
      this.instructions = '';
      this.seller_name = '';
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.barcodes.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(item: any): void {
    const index = this.barcodes.indexOf(item);

    if (index >= 0) {
      this.barcodes.splice(index, 1);
    }
  }

  onNameClear(event) {
    this.name_selected = { _id: null, name: 'Select' };
  }

  createItem() {
    if (this.category && this.category !== '') {
      if (
        this.price &&
        this.price >= 0 &&
        ((this.name_selected &&
          this.name_selected.name &&
          this.name_selected.name !== 'Select' &&
          this.name_selected.name.length > 0) ||
          (this.nameText !== '' && this.nameText)) &&
        ((this.qty && this.qty !== 0 && this.category !== Constants.SERVICES_VALUE) ||
          this.category === Constants.SERVICES_VALUE)
      ) {
        if (!this.nameText && this.name_selected._id && this.category !== Constants.SERVICES_VALUE) {
          this.createBatchItem();
        } else {
          this.blockUI.start();
          const newItem: DrugRequest = {} as DrugRequest;
          newItem.category = this.category;
          if (this.category !== 'SERVICES') {
            newItem.name = this.name_selected.name;
          } else {
            newItem.name = this.nameText;
          }
          newItem.selling_price = this.price;
          // newItem.mrp = this.mrp;
          newItem.qty = this.qty;
          newItem.hsnId = this.hsn_selected && this.hsn_selected._id ? this.hsn_selected._id : '';
          newItem.buying_price = this.buyPrice;
          newItem.supplier = this.seller_name;
          newItem.rack_no = this.rack_no;
          newItem.tax = this.tax;
          newItem.unit = this.unit;
          newItem.manufacturer = this.manufacturer;
          newItem.instructions = this.instructions;
          newItem.barcodes = this.barcodes;
          newItem.custom_batch = this.batch_name;
          if (this.expiry_date && this.expiry_date.date) {
            newItem.expiry_date =
              this.expiry_date.date.year + '-' + this.expiry_date.date.month + '-' + this.expiry_date.date.day;
          }
          this.service.createDrug(newItem).subscribe(
            (res: GlobalResponse) => {
              this.blockUI.stop();
              if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
                this.dialogRef.close(newItem);
              }
            },
            (error: any) => {
              this.blockUI.stop();
              this.toastr.error(error);
            }
          );
        }
      } else {
        this.toastr.error('Fill in the * marked mandatory fields');
      }
    } else {
      this.toastr.error('Please select category before proceeding');
    }
  }

  public unitData: string[] = [
    'Ampoules',
    'Bags',
    'Barrels',
    'Bars',
    'Boxes',
    'Boxes/Cartons',
    'Bottles',
    'Cans',
    'Canisters',
    'Cartridges',
    'Centimeters',
    'Cups',
    'Cubic Centimeters (cc)',
    'Cubic Meters',
    'Doses',
    'Dozen',
    'Drops',
    'Fluid Ounces',
    'Gallons',
    'Grams',
    'Inches',
    'Kilocalories (kcal)',
    'Kilograms',
    'Kegs',
    'Litres',
    'Meters',
    'Micrograms (mg)',
    'Millilitres',
    'Milligrams (mg)',
    'Millimeters',
    'None',
    'Ounces',
    'Packages',
    'Packs',
    'Patches',
    'Pellets',
    'Pieces (pcs)',
    'Pints',
    'Pounds',
    'Quarts',
    'Rolls',
    'Sachets',
    'Sheets',
    'Spools/Rolls',
    'Sprays',
    'Strands',
    'Sticks',
    'Strips',
    'Syringes',
    'Tablets',
    'Tons (Metric/Imperial)',
    'Tubes',
    'Units',
    'Vials',
    'Yards'
  ];

  onUnitChange(event: any) {
    this.unit = event;
  }

  onUnitClear(event: any) {
    this.unit = '';
  }

  createBatchItem() {
    const newItem: DrugRequest = {} as DrugRequest;
    newItem.qty = this.qty;
    newItem.buying_price = this.buyPrice;
    newItem.selling_price = this.price;
    newItem.rack_no = this.rack_no;
    newItem.barcodes = this.barcodes;
    newItem.drugId = this.name_selected._id;
    newItem.category = this.name_selected.category;
    if (this.expiry_date && this.expiry_date.date) {
      newItem.expiry_date =
        this.expiry_date.date.year + '-' + this.expiry_date.date.month + '-' + this.expiry_date.date.day;
    }
    this.blockUI.start();
    this.service.createBatch(newItem).subscribe(
      (res: GlobalResponse) => {
        this.blockUI.stop();
        if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
          this.dialogRef.close(newItem);
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.toastr.error(error);
      }
    );
  }

  onNextDateChanged = (event: IMyDateModel) => {
    this.expiry_date = event;
  };
}
