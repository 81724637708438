import { NgModule } from '@angular/core';
import { SharedModule } from '../shared-module/shared-module.module';
import { ClinicInvoiceTemplateComponent } from './clinic-invoice-template/clinic-invoice-template.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NonSecureRoutingModule } from './non-secure-routing.module';
import { NonSecureService } from './non-secure.service';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { PrescriptionTemplateComponent } from './prescription-template/prescription-template.component';
import { ClinicRegistrationComponent } from './register/clinic-registration.component';
import { StoreInvoiceTemplateComponent } from './store-invoice-template/store-invoice-template.component';

@NgModule({
  declarations: [
    LoginComponent,
    HomeComponent,
    ClinicRegistrationComponent,
    ClinicInvoiceTemplateComponent,
    PrescriptionTemplateComponent,
    StoreInvoiceTemplateComponent,
    NotFoundPageComponent
  ],
  providers: [NonSecureService],
  imports: [SharedModule, NonSecureRoutingModule]
})
export class NonSecureModule {}
