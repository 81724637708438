import { Component, EventEmitter, HostListener, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Doctor, GlobalResponse, INavBarItem } from '../../shared-module/models';
import { CommonService } from '../common-service.service';
import { Constants } from '../constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
  doctor: Doctor = {} as Doctor;
  @Output() navToggle = new EventEmitter();
  navLarge = false;

  activeIndex = -1;
  navList: INavBarItem[] = [];
  headerFixed: boolean;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.doctor = JSON.parse(localStorage.getItem('login'));
    let userFlag = false;
    let inventoryFlag = false;
    let checkupFlag = false;
    let dashboardFlag = false;
    let transactionFlag = false;
    let phoneFlag = false;
    let staffFlag = false;
    let eTokenFlag = false;
    if (this.doctor.plan && this.doctor.plan.features) {
      for (const item of this.doctor.plan.features) {
        if (item.feature && item.feature === Constants.USER_MANAGEMENT && item.available) {
          userFlag = true;
        } else if (item.feature && item.feature === Constants.INVENTORY_MANGEMENT && item.available) {
          inventoryFlag = true;
        } else if (item.feature && item.feature === Constants.CHECKUP && item.available) {
          checkupFlag = true;
        } else if (item.feature && item.feature === Constants.DASHBOARD && item.available) {
          dashboardFlag = true;
        } else if (item.feature && item.feature === Constants.TRANSACTIONS && item.available) {
          transactionFlag = true;
        } else if (item.feature && item.feature === Constants.STAFF_MANGEMENT && item.available) {
          staffFlag = true;
        } else if (item.feature && item.feature === Constants.PHONE_CONSULTATION && item.available) {
          phoneFlag = true;
        } else if (item.feature && item.feature === Constants.E_TOKEN && item.available) {
          eTokenFlag = true;
        }
      }
    }

    const petDetailsLink: INavBarItem = {
      name: 'Pet Details',
      icon: 'pets',
      open: false,
      class: '',
      url: '/pet',
      links: [],
      imgPath: '../../../assets/images/FetchPet_blue.svg'
    };

    const billingLink = {
      name: 'Billing',
      icon: 'currency_exchange',
      class: 'billing',
      open: false,
      url: '/billing',
      links: [],
      iconType: 'bill',
      imgPath: '../../../assets/images/Billing.svg'
    };
    const billingLinks: any[] = [];
    if (this.doctor.subordinate_flag) {
      billingLinks.push({ name: 'Invoice History', url: Constants.BILLING_HISTORY_URL });
      billingLinks.push({ name: 'Prescription History', url: Constants.BILLING_PRESCRIPTION_HISTORY_URL });
    } else {
      billingLinks.push({ name: 'Billing Overview', url: Constants.BILLING_DASHBOARD_URL });
      billingLinks.push({ name: 'Invoice History', url: Constants.BILLING_HISTORY_URL });
      billingLinks.push({ name: 'Prescription History', url: Constants.BILLING_PRESCRIPTION_HISTORY_URL });
    }
    billingLink.links = billingLinks;

    const transactionLink: INavBarItem = {
      name: 'Transactions',
      icon: 'receipt_long',
      class: 'transactions',
      open: false,
      url: '/transactions',
      links: [],
      imgPath: '../../../assets/images/transfer.svg'
    };
    const transactionLinks: any[] = [];
    transactionLinks.push({ name: 'Sales Overview', url: Constants.TRANSACTIONS_SALES_URL });
    transactionLinks.push({ name: 'Clinic Transaction List', url: Constants.TRANSACTIONS_CLINIC_URL });
    transactionLinks.push({ name: 'Store Transaction List', url: Constants.TRANSACTIONS_STORE_LIST_URL });
    transactionLinks.push({ name: 'Vet Transaction List', url: Constants.TRANSACTIONS_VET_URL });
    transactionLinks.push({ name: 'Settlements', url: Constants.TRANSACTIONS_SETTLEMENTS_URL });
    transactionLink.links = transactionLinks;

    const inventoryLink: INavBarItem = {
      name: 'Inventory',
      icon: 'local_shipping',
      class: 'inventory',
      open: false,
      url: '/inventory',
      links: [],
      imgPath: '../../../assets/images/Inventory.svg'
    };
    const inventoryLinks: any[] = [];
    if (this.doctor.subordinate_flag) {
      inventoryLinks.push({ name: 'Dashboard', url: Constants.INVENTORY_DASHBOARD_URL });
      inventoryLinks.push({ name: 'Expiry Analysis', url: Constants.INVENTORY_ANALYSIS_URL });
    } else {
      inventoryLinks.push({ name: 'Dashboard', url: Constants.INVENTORY_DASHBOARD_URL });
      inventoryLinks.push({ name: 'Reorder Point', url: Constants.INVENTORY_REORDER_URL });
      inventoryLinks.push({ name: 'Expiry Analysis', url: Constants.INVENTORY_ANALYSIS_URL });
      inventoryLinks.push({ name: 'Stock Movement', url: Constants.INVENTORY_STOCK_MOVEMENT_URL });
      inventoryLinks.push({ name: 'Audit Log', url: Constants.INVENTORY_AUDIT_LOG_URL });
    }
    inventoryLink.links = inventoryLinks;

    const hsnLink: INavBarItem = {
      name: 'HSN/SAC',
      icon: 'addchart',
      class: 'store',
      open: false,
      url: '/hsn',
      links: []
    };
    const hsnLinks: any[] = [];
    if (this.doctor.subordinate_flag) {
      hsnLinks.push({ name: 'Manage HSN List', url: Constants.HSN_LIST_URL });
    } else {
      hsnLinks.push({ name: 'Manage HSN List', url: Constants.HSN_LIST_URL });
      hsnLinks.push({ name: 'HSN Summary', url: Constants.HSN_SUMMARY_URL });
    }
    hsnLink.links = hsnLinks;

    const storeLink: INavBarItem = {
      name: 'Store',
      icon: 'store',
      class: 'store',
      open: false,
      url: '/store',
      links: [],
      imgPath: '../../../assets/images/plus-square-solid.svg'
    };
    const storeLinks: any[] = [];
    if (this.doctor.subordinate_flag) {
      storeLinks.push({ name: 'Create Store Order', url: Constants.STORE_CREATE_ORDER_URL });
      storeLinks.push({ name: 'Continue Store Order', url: Constants.STORE_PENDING_LIST_URL });
      storeLinks.push({ name: 'Completed Store Order', url: Constants.STORE_COMPLETED_LIST_URL });

      // moved to billing --> invoices
      // storeLinks.push({ name: 'Invoice History', url: Constants.STORE_INVOICES_URL });
    } else {
      storeLinks.push({ name: 'Create Store Order', url: Constants.STORE_CREATE_ORDER_URL });
      storeLinks.push({ name: 'Continue Store Order', url: Constants.STORE_PENDING_LIST_URL });
      storeLinks.push({ name: 'Completed Store Order', url: Constants.STORE_COMPLETED_LIST_URL });

      // moved to billing --> invoices
      // storeLinks.push({ name: 'Invoice History', url: Constants.STORE_INVOICES_URL });
      // storeLinks.push({ name: 'Transaction List', url: Constants.STORE_TRANSACTION_LIST_URL });
    }
    storeLink.links = storeLinks;

    const inpatientLink: INavBarItem = {
      name: 'My Patient',
      icon: 'local_hospital',
      class: 'inpatient',
      open: false,
      url: '/inpatient',
      links: [],
      imgPath: '../../../assets/images/CheckupList.svg'
    };
    const inpatientLinks: any[] = [];
    inpatientLinks.push({ name: 'Ongoing List', url: Constants.INPATIENT_PENDING_LIST_URL });
    inpatientLinks.push({ name: 'Completed List', url: Constants.INPATIENT_COMPLETED_LIST_URL });
    inpatientLinks.push({ name: 'Reminder List', url: Constants.INPATIENT_REMINDER_LIST_URL });
    inpatientLink.links = inpatientLinks;

    const profileLink = {
      name: 'Settings',
      icon: 'settings',
      class: 'profile',
      open: false,
      url: '/profile',
      links: [],
      imgPath: '../../../assets/images/users-cog-solid.svg'
    };
    const profileLinks: any[] = [];
    if (!this.doctor.subordinate_flag && staffFlag) {
      profileLinks.push({ name: 'My Profile Settings', url: Constants.DOCTOR_DETAILS_URL });
      profileLinks.push({ name: 'Clinic Profile Settings', url: Constants.CLINIC_DETAILS_URL });
      profileLinks.push({ name: 'Manage Staff', url: Constants.MANAGE_STAFF_URL });
      profileLinks.push({ name: 'My DashBoard', url: Constants.MANAGE_DASHBOARD_URL });
    } else {
      profileLinks.push({ name: 'My Profile Settings', url: Constants.DOCTOR_DETAILS_URL });
      profileLinks.push({ name: 'My DashBoard', url: Constants.MANAGE_DASHBOARD_URL });
    }
    profileLink.links = profileLinks;

    const cliExpLink = {
      name: 'Expenses',
      icon: 'account_balance_wallet',
      class: 'profile',
      open: false,
      url: '/expense',
      links: [],
      imgPath: '../../../assets/images/users-cog-solid.svg'
    };
    const cliExpLinks: any[] = [];
    if (!this.doctor.subordinate_flag) {
      cliExpLinks.push({ name: 'Expense Dashboard', url: Constants.EXPENSE_DASHBOARD_URL });
      cliExpLinks.push({ name: 'Vendors List', url: Constants.VENDORS_LIST_URL });
    }
    cliExpLink.links = cliExpLinks;

    const certificateLink: INavBarItem = {
      name: 'Certificates',
      icon: 'workspace_premium',
      class: 'store',
      open: false,
      url: '/certificate',
      links: [],
      imgPath: '../../../assets/images/transfer.svg'
    };
    const certificateLinks: any[] = [];
    certificateLinks.push({ name: 'Certificate List', url: '/certificate/list' });
    certificateLinks.push({ name: 'Create Certificate', url: '/certificate/create' });
    certificateLink.links = certificateLinks;

    // Pet Details
    this.navList.push(petDetailsLink);

    // My Patient
    if (checkupFlag) {
      this.navList.push(inpatientLink);
    }

    // certificates
    this.navList.push(certificateLink);

    // Inventory Store
    if (inventoryFlag) {
      this.navList.push(inventoryLink);
      this.navList.push(storeLink);
    }

    // Billing Transactions
    if (transactionFlag) {
      this.navList.push(billingLink);
      if (!this.doctor.subordinate_flag && staffFlag) {
        this.navList.push(transactionLink);
      }
    }

    // HSN menu
    if (inventoryFlag) {
      this.navList.push(hsnLink);
    }

    // Expenses menu
    if (transactionFlag && !this.doctor.subordinate_flag) {
      this.navList.push(cliExpLink);
    }

    // this.navList.push(createNewPetLink);

    if (userFlag) {
      this.navList.push(profileLink);
    }
  }

  logout() {
    localStorage.removeItem('login');
    localStorage.removeItem('token');
    this.commonService.doctorList = [];
    this.commonService.logOut().subscribe((res: GlobalResponse) => {
      if (res && res.message && res.message === Constants.STATUS_SUCCESS) {
        this.toastr.success(Constants.SUCCESS_LOGOUT);
        this.router.navigate([Constants.LOGIN_URL], { queryParams: { returnUrl: this.router.url } });
      }
    });
  }

  onProfile() {
    this.router.navigate([Constants.PROFILE_DASHBOARD_URL]);
  }

  toggleNav() {
    this.navLarge = !this.navLarge;
    this.navToggle.emit(this.navLarge);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const screenPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (screenPosition > 65) {
      this.headerFixed = true;
    } else if (this.headerFixed && screenPosition < 40) {
      this.headerFixed = false;
    }
  }
}
