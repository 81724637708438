<div class="col-md-12 pageTitle">
  <label class="pt-4 pl-4">Pet Certificate List</label>
</div>

<div class="col-md-12">
  <div class="container">
    <div class="row mt-2 mb-1">
      <div class="col-lg-2 col-md-2 text-left">
        <ng-select
          [items]="doctorList"
          bindLabel="name"
          [clearable]="false"
          [addTag]="false"
          [multiple]="false"
          placeholder="Select Doctor"
          (change)="onChange($event)"
          [(ngModel)]="doctor_selected"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.name }}">{{ item.name }}</div>
          </ng-template>
        </ng-select>
      </div>
      <div class="col-lg-3 col-md-3">
        <input
          type="text"
          inputText
          allowType="TEXTDIGITSPCL"
          class="searchBox"
          (keyup)="search$.next($event.target.value)"
          inputText
          placeholder="Search by Owner Phone/Pet Name/Certificate No"
          maxlength="20"
        />
      </div>
      <div class="col-lg-3 col-md-3">
        <ng-select
          [items]="certificates"
          bindLabel="label"
          [clearable]="true"
          [addTag]="false"
          [multiple]="false"
          placeholder="Select Certificate"
          (change)="categoryChanged(false)"
          (clear)="categoryChanged(false)"
          [(ngModel)]="certificate_selected"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.value }}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
      </div>
      <div class="col-lg-1 col-md-1">
        <button
          class="btn btn-primary btn-block"
          title="Click to create new Certificate"
          (click)="navigateToCreateCertificate()"
        >
          <em class="fa fa-plus"></em>&nbsp;&nbsp;&nbsp;New
        </button>
      </div>
      <div class="col-lg-3 col-md-3">
        <my-date-range-picker
          name="mydaterange"
          class=""
          [options]="myDateRangePickerOptions"
          [(ngModel)]="dateRange"
          required
          (dateRangeChanged)="changeDate($event)"
        >
        </my-date-range-picker>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-hover table-bordered billingTable">
        <thead class="turTable">
          <tr class="">
            <th class="cellHeader text-center">SI. No.</th>
            <th class="cellHeader text-center">Date</th>
            <th class="cellHeader text-center">Certificate No</th>
            <th class="cellHeader text-center">Type of Certificate</th>
            <th class="cellHeader text-center">Doctor's Name</th>
            <th class="cellHeader text-center">Owner's Name</th>
            <th class="cellHeader text-center">Owner's Phone</th>
            <th class="cellHeader text-center">Pet</th>
            <th class="cellHeader text-center">Link</th>
            <th class="cellHeader text-center">Action</th>
          </tr>
        </thead>
        <tbody *ngIf="certificateList">
          <tr *ngFor="let item of certificateList; let index = index" class="">
            <td class="cellValue text-center">{{ pageNumber * pageSize + index + 1 }}</td>
            <td class="cellValue text-center">{{ item.updatedAt | date: 'dd-MM-yyyy hh:mm a' }}</td>
            <td class="cellValue text-right">{{ item.certificateNumber }}</td>
            <td class="cellValue">{{ formatCertificateName(item.certificateType) }}</td>
            <td class="cellValue">
              {{ item.doctor.salutation }} {{ item.doctor.first_name }} {{ item.doctor.last_name }}
            </td>
            <td class="cellValue">{{ item.customer.first_name || 'NA' }} {{ item.customer.last_name }}</td>
            <td class="cellValue">{{ item.customer.phone_no || 'NA' }}</td>
            <td class="cellValue">{{ item.pet.name }}</td>
            <td class="cellValue text-center">
              <a
                class="cursor-pointer action-icons mr-2"
                (click)="openCertificate(item._id)"
                target="_blank"
                title="Open Certificate"
                ><mat-icon>preview</mat-icon></a
              >
            </td>
            <td class="cellValue text-center">
              <button
                type="button"
                class="fa fa-list-alt invoiceTur btn"
                (click)="editCertificate(item)"
                title="Click to edit Certificate"
              >
                Edit
              </button>
              <em
                type="button"
                class="fa ml-2 fa-times-circle deleteIcon"
                (click)="viewAction(item, 'delete')"
                title="Click to delete Certificate"
                *ngIf="!doctor.subordinate_flag"
              ></em>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <mat-paginator
      [length]="length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent($event)"
    >
    </mat-paginator>
  </div>
</div>
