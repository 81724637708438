<!-- Header Section -->
<nav class="navbar navbar-expand-md navbar-dark header-content d-flex align-items-center">
  <a class="navbar-brand col-md-5 col-5" routerLink="/pet">
    <img alt="PawsNme" src="../../../assets/images/Logo white option_380_100.svg" class="logo-header" />
    
  </a>
</nav>

<div class="not-found-container">
  <div class="content">
    <p class="description" >
       The page you are
      looking for doesn't exist. Don't worry, you can easily navigate back to where you need to be.
    </p>
    <div class="navigation">
      <button (click)="goToLogin()" class="styled-btn">Go to <strong>Login</strong></button>
    </div>
  </div>
</div>
<app-footer></app-footer>
