import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../../shared-module/constants';
import { Doctor, DoctorRegister } from '../../shared-module/models';
import { NonSecureService } from '../non-secure.service';

@Component({
  selector: 'app-clinic-registration',
  templateUrl: './clinic-registration.component.html',
  styleUrls: ['./clinic-registration.component.scss']
})
export class ClinicRegistrationComponent implements OnInit {
  states: string[] = [];
  doctor: any = {} as any;
  @BlockUI() blockUI: NgBlockUI;
  constructor(
    private service: NonSecureService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.states = this.service.getStates();
  }

  registerDoc() {
    if (
      this.doctor &&
      this.doctor.firstName &&
      this.validateText(this.doctor.firstName) &&
      this.doctor.lastName &&
      this.validateText(this.doctor.lastName)
    ) {
      if (this.doctor.emailId && this.validateEmailAddress(this.doctor.emailId)) {
        if (this.doctor.mobileNumber && this.validateMobileNumber(this.doctor.mobileNumber)) {
          if (!this.doctor.state) {
            this.toastr.error('Please select a state');
            return;
          }

          if (
            this.doctor.address1 &&
            this.doctor.address2 &&
            this.doctor.city &&
            this.doctor.state &&
            this.doctor.pincode &&
            this.doctor.clinicName
          ) {
            const docPayload: DoctorRegister = {
              firstName: this.doctor.firstName,
              lastName: this.doctor.lastName,
              salutation: this.doctor.salutation,
              qualification: this.doctor.qualification,
              keySkills: this.doctor.keySkills,
              emailId: this.doctor.emailId,
              mobileNumber: this.doctor.mobileNumber,
              clinicName: this.doctor.clinicName,
              addressLine1: this.doctor.address1,
              addressLine2: this.doctor.address2,
              city: this.doctor.city,
              state: this.doctor.state,
              pincode: this.doctor.pincode
            };
            this.blockUI.start();
            this.service.registerDoctor(docPayload).subscribe(
              response => {
                this.blockUI.stop();
                this.toastr.success('Registration successful');
                this.doctor = {};
                window.alert('Thanks for registering with us. Our team will contact you soon');
                this.router.navigate([Constants.HOME_URL]);
              },
              error => {
                this.blockUI.stop();
                this.toastr.error(error);
              }
            );
          } else {
            this.toastr.error('Doctor clinic details are must');
          }
        } else {
          this.toastr.error('Mobile number is must and must be valid one');
        }
      } else {
        this.toastr.error('Email id is must and must be valid one');
      }
    } else {
      this.toastr.error('Invalid name fields');
    }
  }

  validateMobileNumber(mobile_number) {
    const mobile_regex = /^[3-9]{1}[0-9]{9}$/;
    if (mobile_regex.test(mobile_number)) {
      return true;
    } else {
      return false;
    }
  }

  validateEmailAddress = email_id => {
    const email_regex =
      /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email_regex.test(email_id.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  validateText = text => {
    const letters = /^[a-zA-Z \s]+$/;
    if (text.match(letters)) {
      return true;
    } else {
      return false;
    }
  };

  hasNumber(mobileNumber) {
    return /^\d+$/.test(mobileNumber);
  }
}
