import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cancel-checkup-dialog',
  templateUrl: './cancel-checkup-dialog.component.html',
  styleUrls: ['./cancel-checkup-dialog.component.scss']
})
export class CancelCheckupDialogComponent {
  public reason = 'Select';
  constructor(
    public dialogRef: MatDialogRef<CancelCheckupDialogComponent>,
    private toastr: ToastrService
  ) {}

  cancelCheckup() {
    if (this.reason && this.reason !== 'Select') {
      this.dialogRef.close(this.reason);
    } else {
      this.toastr.error('Please select any reason from dropdown to proceed');
    }
  }
}
