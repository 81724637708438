<!-- <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="dialogRef.close(false)">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button> -->

<h1 mat-dialog-title class="text-center dialogHeader blueHeader text-white">Payment</h1>
<mat-dialog-content class="mb-4">
  <div class="paymentType text-center">
    <span class="required">Payment Option: &nbsp;</span>
    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" [(ngModel)]="payment" value="CASH" id="cash" name="payment" class="custom-control-input" />
      <label class="custom-control-label" for="cash">Cash</label>
    </div>
    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" [(ngModel)]="payment" value="CARD" id="card" name="payment" class="custom-control-input" />
      <label class="custom-control-label" for="card">Card</label>
    </div>
    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" [(ngModel)]="payment" value="UPI" id="upi" name="payment" class="custom-control-input" />
      <label class="custom-control-label" for="upi">UPI</label>
    </div>
  </div>
  <div class="mt-3">
    <div class="paymentDiv col-md-12 mb-2">
      <label for="paymentAmount" class="payingClinic col-md-6 required">Paying(&#8377;) in Clinic</label>
      <input
        type="text"
        inputText
        allowType="DIGITSWTHDOT"
        id="paymentAmount"
        [(ngModel)]="paymentAmount"
        class="form-control input-font paymentAmount discountBox col-md-6"
        (change)="changeAmount()"
      />
    </div>
    <div class="pendingDiv col-md-12 mb-2">
      <label for="pendingAmount" class="toPay col-md-6 required">Pending(&#8377;) to Pay in App</label>
      <input
        type="text"
        class="form-control input-font pendingAmount discountBox col-md-6"
        id="pendingAmount"
        [(ngModel)]="pendingAmount"
        readonly
      />
    </div>
    <div class="pendingDiv col-md-12 mb-2">
      <label for="advanceAmount" class="toPay col-md-6 required">Advance Paid</label>
      <input
        type="text"
        class="form-control input-font pendingAmount discountBox col-md-6"
        id="advanceAmount"
        [(ngModel)]="advanceAmount"
        readonly
      />
    </div>
    <section *ngIf="invoiceId && invoiceId.length > 0 && paymentType === 'bill'" class="text-center pt-4">
      <input type="checkbox" id="checkboxSelection" [(ngModel)]="checkboxSelection" />
      <label for="checkboxSelection" class="text-info checkboxSelection ml-2">{{ confirmCheckboxLabel }}</label>
    </section>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button
      mat-button
      class="mat-raised-button mr-4 btn blankTurquoise btnWidth"
      (click)="dialogRef.close(false)"
      title="Click to cancel payment"
    >
      Cancel
    </button>
    <button
      mat-button
      class="mat-raised-button btn turquoiseBtn btnWidth"
      (click)="confirmPayment()"
      title="Click to confirm payment"
    >
      Confirm
    </button>
  </div>
</mat-dialog-actions>
