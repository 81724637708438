<div class="bg-content">
  <div>
    <img class="text-left logo" alt="Pawsnme" src="./assets/images/Logo-03.png" />
  </div>
  <div class="text-center bg-content">
    <div class="content-padding">
      <p class="content-title mb-0"><strong>Vet Solution by PawsNme</strong></p>
      <p class="content-text pt-1 mb-2">Cloud based easy to use application for vet doctors</p>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <form>
            <div class="form-group row">
              <label for="inputDoctorSalutation" class="col-sm-4 col-form-label text-right">Salutation</label>
              <div class="col-sm-8">
                <label class="mr-3">
                  <input
                    type="radio"
                    name="salutation"
                    [(ngModel)]="doctor.salutation"
                    id="option1"
                    value="Dr."
                    autocomplete="off"
                  />
                  Dr.
                </label>
                <label class="mr-3">
                  <input
                    type="radio"
                    name="salutation"
                    [(ngModel)]="doctor.salutation"
                    id="option2"
                    value="Mr."
                    autocomplete="off"
                  />
                  Mr.
                </label>
                <label class="mr-3">
                  <input
                    type="radio"
                    name="salutation"
                    [(ngModel)]="doctor.salutation"
                    id="option3"
                    value="Mrs."
                    autocomplete="off"
                  />
                  Mrs.
                </label>
                <label class="mr-3">
                  <input
                    type="radio"
                    name="salutation"
                    [(ngModel)]="doctor.salutation"
                    id="option4"
                    value="Ms."
                    autocomplete="off"
                  />
                  Ms.
                </label>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputDoctorFirstName" class="col-sm-4 col-form-label text-right">First name</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  autofocus
                  [(ngModel)]="doctor.firstName"
                  maxlength="30"
                  inputText
                  allowType="ONLYTEXTS"
                  [ngModelOptions]="{ standalone: true }"
                  id="inputDoctorFirstName"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="inputDoctorLastName" class="col-sm-4 col-form-label text-right">Last name</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="doctor.lastName"
                  maxlength="30"
                  inputText
                  allowType="ONLYTEXTS"
                  [ngModelOptions]="{ standalone: true }"
                  id="inputDoctorLastName"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="inputDoctorQualification" class="col-sm-4 col-form-label text-right">Qualification </label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="doctor.qualification"
                  [ngModelOptions]="{ standalone: true }"
                  id="inputDoctorQualification"
                  placeholder="Qualification"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPhonenumber" class="col-sm-4 col-form-label text-right">Phone number</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="doctor.mobileNumber"
                  maxlength="10"
                  [ngModelOptions]="{ standalone: true }"
                  id="inputPhonenumber"
                  placeholder="Phone number"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail" class="col-sm-4 col-form-label text-right">Email</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  inputText
                  allowType="TEXTEMAIL"
                  class="form-control"
                  id="inputEmail"
                  [(ngModel)]="doctor.emailId"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="Enter EmailId"
                  maxlength="60"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="inputKeyskills" class="col-sm-4 col-form-label text-right">Keyskills</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  id="inputKeyskills"
                  [(ngModel)]="doctor.keySkills"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="Keyskills"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="inputClinicName" class="col-sm-4 col-form-label text-right">Clinic name</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="doctor.clinicName"
                  [ngModelOptions]="{ standalone: true }"
                  id="inputClinicName"
                  placeholder="Clinic name"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="inputAddress1" class="col-sm-4 col-form-label text-right">Clinic Address 1</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="doctor.address1"
                  [ngModelOptions]="{ standalone: true }"
                  id="inputAddress1"
                  placeholder="Clinic Address 1"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="inputAddress2" class="col-sm-4 col-form-label text-right">Clinic Address 2</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="doctor.address2"
                  [ngModelOptions]="{ standalone: true }"
                  id="inputAddress2"
                  placeholder="Clinic Address 2"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="inputCity" class="col-sm-4 col-form-label text-right">City</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="doctor.city"
                  [ngModelOptions]="{ standalone: true }"
                  id="inputCity"
                  placeholder="City"
                />
              </div>
            </div>

            <div class="form-group row">
              <label for="inputState" class="col-sm-4 col-form-label text-right">State</label>
              <div class="col-sm-8">
                <select
                  id="inputState"
                  class="form-control"
                  [(ngModel)]="doctor.state"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option value="undefined" disabled selected hidden>State</option>
                  <option *ngFor="let state of states" [value]="state">{{ state }}</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label for="inputZipcode" class="col-sm-4 col-form-label text-right">Zipcode</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  maxlength="6"
                  [(ngModel)]="doctor.pincode"
                  [ngModelOptions]="{ standalone: true }"
                  id="inputZipcode"
                  placeholder="Zipcode"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-10"></div>
            </div>
          </form>
          <div class="form-group row">
            <div class="col-sm-12 pl-4 text-right">
              <button type="submit" class="btn btn-primary col-sm-8" (click)="registerDoc()">Register</button>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
      <p class="content-text pt-2 mb-2">
        Register now and our team will contact you within 24 hours to access our <br />
        user friendly vet doctor's application for managing your clients and booking
      </p>
    </div>
  </div>
</div>
<app-footer></app-footer>
<block-ui></block-ui>
