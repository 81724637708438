<footer id="footerid" class="page-footer font-small blue footerd">
  <div class="text-center text-md-left">
    <div class="">
      <div class="mt-md-0 mt-3 text-center">
        <h5 class="d-inline-block text-uppercase text-center">
          <!-- <img src="../../../assets/images/logo_new.png" class="footer-logo" alt="Logo"> -->
        </h5>
        <p class="d-inline-block pb-0 mb-0 text-center pr-2">
          © 2020-{{ currentYear }}
          <strong>
            <a href="https://www.pawsnme.com/" target="_blank">PawsNme</a>
          </strong>
          &nbsp;&nbsp;All Rights Reserved&nbsp;&nbsp;|
          <span class="verison-info p-1">Version: {{ releaseVersion }}</span> |
        </p>
        <p class="d-inline-block pt-0 mt-1 mb-1 text-center">
          <a [routerLink]="['/extra/support']" class="text-underline">Support</a>
        </p>
      </div>
    </div>
  </div>
</footer>
