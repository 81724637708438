import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../../shared-module/common-service.service';
import { Constants } from '../../shared-module/constants';
import { Doctor, Note } from '../../shared-module/models';

@Injectable({
  providedIn: 'root'
})
export class CheckupService {
  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService
  ) {}

  getVaccinationRecords(doctor: Doctor, pet_id: string, itemsPerPage: number = 4, pageNumber: number = 0) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.DOCTOR_VACCINATION_API +
        pet_id,
      { params }
    );
  }

  getCheckupHistory(doctor: Doctor, pet_id: string, itemsPerPage: number = 4, pageNumber: number = 0) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.PET_API +
        pet_id +
        Constants.CHECKUP_COMPLETED_API,
      { params }
    );
  }

  getReminderHistory(
    doctor: Doctor,
    pet_id: string,
    type: number = 1,
    itemsPerPage: number = 4,
    pageNumber: number = 0
  ) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_TYPE, type.toString())
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(
      Constants.MAIN_ENV_URL + Constants.CLINIC_API + doctor.clinics[0].clinic._id + Constants.PET_API + pet_id,
      { params }
    );
  }

  getMedicineRecords(
    doctor: Doctor,
    pet_id: string,
    itemsPerPage: number = 4,
    pageNumber: number = 0,
    category: string = ''
  ) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.CATEGORY, category)
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.MEDICINE_RECORDS_API +
        pet_id,
      { params }
    );
  }

  getCheckupList(
    doctor: Doctor,
    pet_id: string,
    itemsPerPage: number = 4,
    pageNumber: number = 0,
    category: string = ''
  ) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.CATEGORY, category)
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.MEDICINE_RECORDS_API +
        pet_id,
      { params }
    );
  }

  getLabRecords(doctor: Doctor, pet_id: string, itemsPerPage: number = 4, pageNumber: number = 0) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.LAB_REPORTS_API +
        pet_id,
      { params }
    );
  }

  getSurgeryRecords(doctor: Doctor, pet_id: string, itemsPerPage: number = 4, pageNumber: number = 0) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.SURGERY_RECORDS_API +
        pet_id,
      { params }
    );
  }

  getMedicalHistoryRecords(doctor: Doctor, pet_id: string, itemsPerPage: number = 4, pageNumber: number = 0) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.MEDICAL_HISTORY_LIST_API +
        pet_id,
      { params }
    );
  }

  getOthersRecords(doctor: Doctor, pet_id: string, itemsPerPage: number = 4, pageNumber: number = 0) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.OTHERSERVICES_LIST_API +
        pet_id,
      { params }
    );
  }

  getData(doctor: Doctor, drugType: string, itemsPerPage: number = 0, pageNumber: number = 0) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DRUG_LIST_API +
        doctor._id +
        Constants.URL_DASH +
        doctor.clinics[0].clinic._id +
        Constants.URL_DASH +
        drugType,
      { params }
    );
  }

  getPurpsOfVisitData(doctor: Doctor, itemsPerPage: number = 0, pageNumber: number = 0) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(Constants.MAIN_ENV_URL + Constants.DOCTOR_API + doctor._id + Constants.PURPOSES_API, {
      params
    });
  }

  getProcedureData(doctor: Doctor, itemsPerPage: number = 0, pageNumber: number = 0) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(Constants.MAIN_ENV_URL + Constants.DOCTOR_API + doctor._id + Constants.PROCEDURE_API, {
      params
    });
  }

  getDiagnosticData(doctor: Doctor, itemsPerPage: number = 0, pageNumber: number = 0) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(Constants.MAIN_ENV_URL + Constants.DOCTOR_API + doctor._id + Constants.DIAGNOSTIC_API, {
      params
    });
  }

  updateTodayCheckup(reqObj: any, checkup_id: string) {
    return this.httpClient.put(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_CHECKUP_API + checkup_id + Constants.SUBMIT_API,
      reqObj
    );
  }

  saveTodayCheckup(reqObj: any, checkup_id: string) {
    return this.httpClient.patch(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_CHECKUP_API + checkup_id + Constants.SAVE_API,
      reqObj
    );
  }
  searchPet(customer_id: string, pet_id: string, doctor: Doctor) {
    const params = new HttpParams().set('clinic', doctor.clinics[0].clinic._id);
    return this.httpClient.get(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_CUSTOMER_API + customer_id + Constants.PET_API + pet_id,
      { params }
    );
  }

  updateTodayCheckupPayment(reqObj: any) {
    const body = {
      amount: reqObj.amount,
      paymentMethod: reqObj.paymentMethod
    };
    return this.httpClient.post(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        reqObj.doctorId +
        Constants.SLASH_CLINIC_API +
        reqObj.clinicId +
        Constants.SLASH_INVOICE_SLASH_API +
        reqObj.invoiceId +
        Constants.PAYMENT_API,
      body
    );
  }

  uploadDiagnosticReport(file: any, labId: string) {
    const formData: FormData = new FormData();
    formData.append('labReport', file);
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DOCTOR_UPLOAD_LAB_REPORT_API + labId, formData);
  }

  uploadSurgeryReport(file: any, surId: string) {
    const formData: FormData = new FormData();
    formData.append('surgeryReport', file);
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DOCTOR_UPLOAD_SURGERY_REPORT_API + surId, formData);
  }

  uploadVaccinationReport(file: any, vaccinationId: string) {
    const formData: FormData = new FormData();
    formData.append('vaccinationReport', file);

    return this.httpClient.post(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_UPLOAD_VACCINATION_REPORT_API + vaccinationId,
      formData
    );
  }

  uploadHealthReport(file: File, medicalHistoryId: string) {
    const formData: FormData = new FormData();
    formData.append('petImage', file);
    return this.httpClient.post(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_UPLOAD_MEDICAL_HISTORY_API + medicalHistoryId,
      formData
    );
  }

  notesList(doctor: Doctor, pet_id: string, itemsPerPage: number = 3, pageNumber: number = 0) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.PET_API +
        pet_id +
        Constants.NOTES_LIST_API,
      { params }
    );
  }

  pendingCheckupList(doctor: Doctor, itemsPerPage: number = 10, pageNumber: number = 0) {
    const params = new HttpParams()
      .set(Constants.QUERY_PARAMS_ITEMS_PER_PAGE, itemsPerPage.toString())
      .set(Constants.QUERY_PARAMS_PAGE_NUMBER, pageNumber.toString());
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.URL_DASH +
        Constants.CHECKUP_PENDING_API,
      { params }
    );
  }

  addNotes(
    doctor: Doctor,
    title: string,
    petWeight: number,
    pet_id: string,
    petFindingsAndStatus: string,
    petFliudIntake: number,
    checkupId: string
  ) {
    return this.httpClient.post(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.PET_API +
        pet_id +
        Constants.SLASH_CHECKUP_SLASH +
        checkupId +
        Constants.NOTES_ADD_API,
      { title, petWeight, petFindingsAndStatus, petFliudIntake }
    );
  }

  addLOT(doctor: Doctor, pet_id: string, noteID: string, description: string) {
    return this.httpClient.post(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.PET_API +
        pet_id +
        Constants.NOTES_API +
        noteID +
        Constants.LINE_OF_TREATMENT_ADD_API,
      { description }
    );
  }

  editNotes(
    doctor: Doctor,
    pet_id: string,
    currentNote: Note,
    petFindingsAndStatus: string,
    petWeight: number,
    petFliudIntake: number
  ) {
    return this.httpClient.put(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.PET_API +
        pet_id +
        Constants.NOTES_API +
        currentNote._id,
      { petFindingsAndStatus, petWeight, petFliudIntake }
    );
  }

  editLOT(doctor: Doctor, pet_id: string, currentNote: Note, currentLineItem: any, description: string) {
    return this.httpClient.put(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.PET_API +
        pet_id +
        Constants.NOTES_API +
        currentNote._id +
        Constants.LINE_OF_TREATMENT_API +
        currentLineItem._id,
      { description }
    );
  }

  deleteNotes(doctor: Doctor, pet_id: string, item: Note) {
    return this.httpClient.delete(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.PET_API +
        pet_id +
        Constants.NOTES_API +
        item._id,
      {}
    );
  }

  deleteLineItem(doctor: Doctor, pet_id: string, item: Note, lineItem: any) {
    return this.httpClient.delete(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.PET_API +
        pet_id +
        Constants.NOTES_API +
        item._id +
        Constants.LINE_OF_TREATMENT_API +
        lineItem._id
    );
  }

  addCheckUpItem(doctor: Doctor, checkup_id: string, checkupItem: any, type: string) {
    checkupItem.clinicId = doctor.clinics[0].clinic._id;
    checkupItem.doctorId = doctor._id;
    return this.httpClient.post(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_CHECKUP_API + checkup_id + Constants.URL_DASH + type,
      checkupItem
    );
  }

  updateCheckUpItem(checkup_id: string, checkupItem: any, type: string, itemId: string) {
    return this.httpClient.put(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_CHECKUP_API +
        checkup_id +
        Constants.URL_DASH +
        type +
        Constants.URL_DASH +
        itemId,
      checkupItem
    );
  }

  reInitializeCheckup(petId: string, customerId: string, channel: string) {
    const payLoad = {
      petId,
      customerId,
      channel,
      doctorId: this.commonService.doctorValue._id,
      clinicId: this.commonService.doctorValue.clinics[0].clinic._id
    };
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DOCTOR_CHECKUP_REINTIALIZE_API, payLoad);
  }

  submitCheckup(doctor: Doctor, checkupItem: any) {
    checkupItem.clinicId = doctor.clinics[0].clinic._id;
    checkupItem.doctorId = doctor._id;
    return this.httpClient.put(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_CHECKUP_API + checkupItem._id + Constants.SUBMIT_API,
      checkupItem
    );
  }

  deleteCheckUpItem(doctor: Doctor, checkup_id: string, type: string, itemId: any) {
    const clinicId = doctor.clinics[0].clinic._id;
    const doctorId = doctor._id;

    const options = {
      headers: new HttpHeaders(),
      body: {
        clinicId,
        doctorId
      }
    };
    return this.httpClient.delete(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_CHECKUP_API +
        checkup_id +
        Constants.URL_DASH +
        type +
        Constants.URL_DASH +
        itemId,
      options
    );
  }

  intializeCheckup(doctor: Doctor, petId: string, customerId: any) {
    const checkupItem = {
      clinicId: doctor.clinics[0].clinic._id,
      doctorId: doctor._id,
      customerId,
      petId
    };
    return this.httpClient.post(Constants.MAIN_ENV_URL + Constants.DOCTOR_CHECKUP_INTIALIZE_API, checkupItem);
  }

  getCheckupDetails(doctor: Doctor, checkup_id: string) {
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.SLASH_CHECKUP_SLASH +
        checkup_id
    );
  }

  updateCheckupDoctor(doctor: Doctor, checkup_id: string) {
    return this.httpClient.patch(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_API + doctor._id + Constants.SLASH_CHECKUP_SLASH + checkup_id,
      {}
    );
  }

  searchPrescription(searchValue: string) {
    const params = new HttpParams().set(Constants.LABEL_SEARCH, searchValue.toString());
    return this.httpClient.get(Constants.MAIN_ENV_URL + Constants.SUMMARY_DRUG_API, { params });
  }

  addDashboardItem(reqObj: any, type: string) {
    return this.httpClient.post(
      Constants.MAIN_ENV_URL + Constants.DOCTOR_API + this.commonService.doctorValue._id + type,
      reqObj
    );
  }

  getBatchList(doctor: Doctor, drugId: string) {
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.SLASH_DRUG_SLASH_API +
        drugId +
        Constants.SLASH_BATCHES_API
    );
  }

  cancelCheckup(doctor: Doctor, checkupId: string, channel: string, remarks: any) {
    const putItem = {
      channel,
      remarks
    };
    return this.httpClient.put(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.SLASH_CHECKUP_SLASH +
        checkupId +
        Constants.CANCEL_CHECKUP_API,
      putItem
    );
  }

  getBatchDetails(doctor: Doctor, drugId: string, batchId) {
    return this.httpClient.get(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.SLASH_DRUG_SLASH_API +
        drugId +
        Constants.SLASH_BATCH_SLASH_API +
        batchId
    );
  }

  generateInvoice(doctor: Doctor, reqObj: any, checkup_id: string) {
    reqObj.clinicId = doctor.clinics[0].clinic._id;
    reqObj.doctorId = doctor._id;
    return this.httpClient.post(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_API +
        doctor._id +
        Constants.SLASH_CLINIC_API +
        doctor.clinics[0].clinic._id +
        Constants.SLASH_CHECKUP_SLASH +
        checkup_id +
        Constants.SLASH_INVOICE,
      reqObj
    );
  }

  updateItemsPrice(doctor: Doctor, checkupId: string, itemId: string, type: string, price: number) {
    const checkupItem = {
      clinicId: doctor.clinics[0].clinic._id,
      doctorId: doctor._id,
      total_price: price
    };
    return this.httpClient.put(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_CHECKUP_API +
        checkupId +
        Constants.URL_DASH +
        type +
        Constants.URL_DASH +
        itemId,
      checkupItem
    );
  }

  updateLabSurgeryPrice(doctor: Doctor, checkupId: string, itemId: string, type: string, price: number) {
    const checkupItem = {
      clinicId: doctor.clinics[0].clinic._id,
      doctorId: doctor._id,
      price
    };
    return this.httpClient.put(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_CHECKUP_API +
        checkupId +
        Constants.URL_DASH +
        type +
        Constants.URL_DASH +
        itemId,
      checkupItem
    );
  }

  deleteEditBillItem(doctor: Doctor, checkup_id: string, type: string, itemId: any) {
    const clinicId = doctor.clinics[0].clinic._id;
    const doctorId = doctor._id;

    const options = {
      headers: new HttpHeaders(),
      body: {
        clinicId,
        doctorId
      }
    };
    return this.httpClient.delete(
      Constants.MAIN_ENV_URL +
        Constants.DOCTOR_CHECKUP_API +
        checkup_id +
        Constants.URL_DASH +
        type +
        Constants.URL_DASH +
        itemId,
      options
    );
  }

  sendNotification(checkupItem: any, checkupId: string) {
    return this.httpClient.patch(
      Constants.MAIN_ENV_URL + Constants.SLASH_CHECKUP_API + checkupId + Constants.NOTIFIER_API,
      checkupItem
    );
  }
}
